import React,{useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function DeleteBanner() {
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await axios.delete(`${serverURL}banner/${id}`,{
        headers: {
          authorization: token,
        },
      });
      navigate("/banner"); 
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };

  return (
    <div>
      <h1>Delete Banner</h1>
      <p>Are you sure you want to delete this Banner?</p>
      <button onClick={handleDelete}>Confirm Delete</button>
    </div>
  );
}