import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function ShowArticle() {

	const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);


	const navigate = useNavigate();

	const [articles, setStretchings] = useState([])
	const [searchQuery, setSearchQuery] = useState("");


	useEffect(() => {
		async function getResults() {
			try {
				const data = await (await axios.get(`${serverURL}articles`)).data
				setStretchings(data)
			} catch (error) {
			}
		}
		getResults()
	}, []);

	const filteredStretchings = articles.filter((article) =>
  article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  article.language.toLowerCase().includes(searchQuery.toLowerCase())
	);

	return (
		<>
			<div className="dashboard d-flex">
				<div>
					<Sidebar />
				</div>
				<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
					<div style={{ height: "100%" }}>
						<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

							<div className='row'>
								<div className="col-md-12">
								<input
										type="text"
                    placeholder="Search by Title or Language"
										value={searchQuery}
										onChange={(e) => setSearchQuery(e.target.value)
                    }
									/>
									<table className='table table-bordered'>
										<thead className='bs thead-dark'>
											<tr>
												<th>#</th>
												<th>Title</th>
												<th>Image1</th>
                        <th>Image2</th>
												<th>content</th>
                        <th>langugage</th>
                        <th>date</th>
                                                <th>Operations</th>
											</tr>
										</thead>
										<tbody>
											{articles && (filteredStretchings.map((stretching,index) => {
												const bodypartTitles = stretching.content;

                                                const handleEdit = () => {
													navigate(`/articles/edit/id=${stretching._id}`);
												  };
									  
												  const handleDelete = () => {
													navigate(`/articles/delete/id=${stretching._id}`);
												  };
									  
												return <tr key={stretching._id}>
													<td>{index+1}</td>
													<td>{stretching.title}</td>
													<td>{stretching.image1 && (<img src={`${serverURL}` + stretching.image1} alt={stretching.title} style={{ width: "100px" }} />)}</td>
                          <td>{stretching.image2 && (<img src={`${serverURL}` + stretching.image2} alt={stretching.title} style={{ width: "100px" }} />)}</td>
													<td>{bodypartTitles}</td>
                          <td>{stretching.language}</td>
                          <td>{stretching.date}</td>
													<td><button className="btn btn-sm btn-primary" onClick={handleEdit}>Edit</button>
														<button className="btn btn-sm btn-danger" onClick={handleDelete}>Delete</button></td>
												</tr>
											}))}
										</tbody>
									</table>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}
