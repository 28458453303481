import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import getCookie from "./getCookie";
import axios from "axios";
import { serverURL } from "../serverURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Table, Timeline } from "flowbite-react";
import moment from "moment";
const Referral = () => {
  const token = getCookie("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [history, setHistory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };

    const getReferralsData = async () => {
      try {
        const response = await axios.get(`${serverURL}admin/referral`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        let data = response.data?.data;
        setData(data);
        console.log(data);
      } catch (error) {}
    };

    getUser();
    getReferralsData();
  }, [token]);

  const onViewHistory = (dta) => {
    setModalOpen(true);
    setHistory(dta);
  };

  const onClose = () => {
    setHistory(null);
    setModalOpen(false);
  };

  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div className="p-3 w-full flex max-h-screen overflow-scroll">
        {data && data.length > 0 && (
          <Table striped>
            <Table.Head>
              <Table.HeadCell>S.NO</Table.HeadCell>
              <Table.HeadCell>UID</Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Referral Code</Table.HeadCell>
              <Table.HeadCell>Referred</Table.HeadCell>{" "}
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {data.map((item, i) => {
                let {
                  FirstName,
                  LastName,
                  Email,
                  ReferralCode,
                  uid,
                  referred,
                  gdCoinsHistory,
                } = item;
                let name = FirstName
                  ? FirstName
                  : "" + " " + LastName
                  ? LastName
                  : "";
                return (
                  <Table.Row className="bg-white">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900">
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>{uid}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{Email}</Table.Cell>
                    <Table.Cell>{ReferralCode}</Table.Cell>
                    <Table.Cell>{referred.length}</Table.Cell>
                    {gdCoinsHistory && gdCoinsHistory.length > 0 && (
                      <Table.Cell
                        className="cursor-pointer"
                        onClick={() => onViewHistory(gdCoinsHistory)}
                      >
                        <FontAwesomeIcon color="green" icon={faEye} />
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>

      <Modal show={modalOpen} onClose={onClose}>
        <Modal.Body>
          <Timeline>
            {history &&
              history.length > 0 &&
              history.map((item, i) => {
                let { date, title, message, coins } = item;
                return (
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>
                        {date && moment(date).format("MMM DD YYYY")}
                      </Timeline.Time>
                      <Timeline.Title>
                        {title} {coins && ` - ${coins} coins`}
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>{message}</p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={onClose} className="mx-auto">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Referral;
