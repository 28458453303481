import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import getCookie from "./getCookie";
import axios from "axios";
import { serverURL } from "../serverURL";
import Swal from "sweetalert2";

const defaultInputProps = {
  error: false,
  errorMessage: "",
  value: 0,
};

const inputState = {
  coinExpiry: {
    ...defaultInputProps,
  },
  coinValue: {
    ...defaultInputProps,
  },
  exerciseReward: {
    ...defaultInputProps,
  },
  referrerReferralReward: {
    ...defaultInputProps,
  },
  userDailyReward: {
    ...defaultInputProps,
  },
  userReferralReward: {
    ...defaultInputProps,
  },
};

const errors = {
  coinValue: "Coin value is required and must be number",
  coinExpiry: "Coin Expiry required and must be number",
  exerciseReward: "Exercise Reward required and must be number",
  referrerReferralReward:
    "Referrer Referral Reward required and must be number",
  userDailyReward: "User Daily Reward required and must be number",
  userReferralReward: "User Referral Reward required and must be number",
};

const GDCoins = () => {
  const token = getCookie("token");
  const [inputs, setInputs] = useState(inputState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };

    const getCoinsData = async () => {
      try {
        const response = await axios.get(`${serverURL}admin/coins`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        let {
          coinExpiry,
          exerciseReward,
          referrerReferralReward,
          userDailyReward,
          userReferralReward,
          coinValue,
        } = response.data?.data;
        let data = {
          coinExpiry: coinExpiry || 0,
          exerciseReward: exerciseReward || 0,
          referrerReferralReward: referrerReferralReward || 0,
          userDailyReward: userDailyReward || 0,
          userReferralReward: userReferralReward || 0,
          coinValue: coinValue || 0,
        };
        Object.keys(data).map((key, i) => {
          let value = data[key];
          setInputs((p) => ({
            ...p,
            [key]: {
              error: false,
              errorMessage: "",
              value: value,
            },
          }));
        });
      } catch (error) {}
    };
    // getUser();
    getCoinsData();
  }, [token]);

  const onValueChange = (key, value) => {
    setInputs((p) => ({
      ...p,
      [key]: {
        ...p[key],
        error: false,
        errorMessage: "",
        value: value,
      },
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    let {
      coinExpiry,
      userDailyReward,
      userReferralReward,
      exerciseReward,
      referrerReferralReward,
      coinValue,
    } = inputs;
    const numberRegex = /^-?\d+(\.\d{2})?$/;
    let hadErrors = false;
    const checkNum = (v) => {
      return numberRegex.test(v);
    };
    const setError = (key, message) => {
      setInputs((p) => ({
        ...p,
        [key]: {
          ...p[key],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };

    if (!coinValue.value || !checkNum(coinValue.value)) {
      setError("coinValue", errors.coinValue);
    }

    if (!coinExpiry.value || !checkNum(coinExpiry.value)) {
      setError("coinExpiry", errors.coinExpiry);
    }
    if (!userDailyReward.value || !checkNum(userDailyReward.value)) {
      setError("userDailyReward", errors.userDailyReward);
    }
    if (!userReferralReward.value || !checkNum(userReferralReward.value)) {
      setError("userReferralReward", errors.userReferralReward);
    }
    if (!exerciseReward.value || !checkNum(exerciseReward.value)) {
      setError("exerciseReward", errors.exerciseReward);
    }
    if (
      !referrerReferralReward.value ||
      !checkNum(referrerReferralReward.value)
    ) {
      setError("referrerReferralReward", errors.referrerReferralReward);
    }

    if (hadErrors) {
      return;
    }
    try {
      setLoading(true);
      let data = {
        coinValue: Number(coinValue.value),
        coinExpiry: Number(coinExpiry.value),
        referrerReferralReward: Number(referrerReferralReward.value),
        exerciseReward: Number(exerciseReward.value),
        userDailyReward: Number(userDailyReward.value),
        userReferralReward: Number(userReferralReward.value),
      };
      const response = await axios.put(`${serverURL}admin/coins`, data, {
        headers: {
          Authorization: `${token}`,
        },
      });
      let responseData = response.data?.data;
      Object.keys(responseData).map((key, i) => {
        let value = data[key];
        setInputs((p) => ({
          ...p,
          [key]: {
            error: false,
            errorMessage: "",
            value: value,
          },
        }));
      });

      Swal.fire(
        "Coins Success!",
        "Coins Value updated successfully!",
        "success"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire("Coins Failure!", "Coins Value updated failed!", "error");
    }
  };

  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div className="p-3 w-full flex items-center justify-center">
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-8">
            <div className="grid grid-cols-2 items-center">
              <h4
                for="first_name"
                className="block mb-2 text-[18px] font-medium text-gray-900 mx-auto"
              >
                Coin Value in Rupees (Ex: 1coin = 0.25Rs)
              </h4>
              <div>
                <input
                  type="number"
                  id="coinValue"
                  className="bg-gray-50 border min-w-[300px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-3.5 "
                  placeholder="Coins value for one GD coin equal to"
                  value={inputs.coinValue.value}
                  onChange={(e) => onValueChange("coinValue", e.target.value)}
                />
                {inputs.coinValue.error && (
                  <p className="text-sm text-red-500 ">
                    {inputs.coinValue.errorMessage}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 items-center">
              <h4
                for="first_name"
                className="block mb-2 text-[18px] font-medium text-gray-900 mx-auto"
              >
                User Daily Reward
              </h4>
              <div>
                <input
                  type="number"
                  id="userDailyReward"
                  className="bg-gray-50 border min-w-[300px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-3.5 "
                  placeholder="Coins to add for maintaining daily streak"
                  value={inputs.userDailyReward.value}
                  onChange={(e) =>
                    onValueChange("userDailyReward", e.target.value)
                  }
                />
                {inputs.userDailyReward.error && (
                  <p className="text-sm text-red-500 ">
                    {inputs.userDailyReward.errorMessage}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 items-center">
              <h4
                for="first_name"
                className="block mb-2 text-[18px] font-medium text-gray-900 mx-auto"
              >
                Coins Expiry (In Days)
              </h4>
              <div>
                <input
                  type="number"
                  id="coinExpiry"
                  className="bg-gray-50 border min-w-[300px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-3.5 "
                  placeholder="Coin expiry"
                  value={inputs.coinExpiry.value}
                  onChange={(e) => onValueChange("coinExpiry", e.target.value)}
                />
                {inputs.coinExpiry.error && (
                  <p className="text-sm text-red-500 ">
                    {inputs.coinExpiry.errorMessage}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 items-center">
              <h4
                for="first_name"
                className="block mb-2 text-[18px] font-medium text-gray-900 mx-auto"
              >
                Exercise Reward
              </h4>
              <div>
                <input
                  type="number"
                  id="exerciseReward"
                  className="bg-gray-50 border min-w-[300px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-3.5 "
                  placeholder="Coins to add on exercise completion"
                  value={inputs.exerciseReward.value}
                  onChange={(e) =>
                    onValueChange("exerciseReward", e.target.value)
                  }
                />
                {inputs.exerciseReward.error && (
                  <p className="text-sm text-red-500 ">
                    {inputs.exerciseReward.errorMessage}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 items-center">
              <h4
                for="first_name"
                className="block mb-2 text-[18px] font-medium text-gray-900 mx-auto"
              >
                Referral Reward for referrer
              </h4>
              <div>
                <input
                  type="number"
                  id="referrerReferralReward"
                  className="bg-gray-50 border min-w-[300px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-3.5 "
                  placeholder="Coins to add for referrer"
                  value={inputs.referrerReferralReward.value}
                  onChange={(e) =>
                    onValueChange("referrerReferralReward", e.target.value)
                  }
                />
                {inputs.referrerReferralReward.error && (
                  <p className="text-sm text-red-500 ">
                    {inputs.referrerReferralReward.errorMessage}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 items-center">
              <h4
                for="first_name"
                className="block mb-2 text-[18px] font-medium text-gray-900 mx-auto"
              >
                Referral Reward for user
              </h4>
              <div>
                <input
                  type="number"
                  id="userReferralReward"
                  className="bg-gray-50 border min-w-[300px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-3.5 "
                  placeholder="Coins to add for user when used referral code"
                  value={inputs.userReferralReward.value}
                  onChange={(e) =>
                    onValueChange("userReferralReward", e.target.value)
                  }
                />
                {inputs.userReferralReward.error && (
                  <p className="text-sm text-red-500 ">
                    {inputs.userReferralReward.errorMessage}
                  </p>
                )}
              </div>
            </div>

            {!loading && (
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                UPDATE DATA
              </button>
            )}

            {loading && (
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                UPDATING DATA PLEASE WAIT...
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default GDCoins;
