import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function ShowMeditation() {

	const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

	const navigate = useNavigate();

	const [meditations, setMeditations] = useState([])

	useEffect(() => {
		async function getResults() {
			try {
				const data = await (await axios.get(`${serverURL}meditation`)).data
				setMeditations(data)
			} catch (error) {
			}
		}
		getResults()
	}, []);

	return (
		<>
			<div className="dashboard d-flex">
				<div>
					<Sidebar />
				</div>
				<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
					<div style={{ height: "100%" }}>
						<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

							<div className='row'>
								<div className="col-md-12">
									<table className='table table-bordered'>
										<thead className='bs thead-dark'>
											<tr>
												<th>#</th>
												<th>Title</th>
												<th>Image</th>
												<th>Music</th>
                                                <th>Operations</th>
											</tr>
										</thead>
										<tbody>
											{meditations && (meditations.map((meditation,index) => {
                                                const handleEdit = () => {
													navigate(`/meditation/edit/id=${meditation._id}`);
												  };
									  
												  const handleDelete = () => {
													navigate(`/meditation/delete/id=${meditation._id}`);
												  };
									  
												return <tr key={meditation._id}>
													<td>{index+1}</td>
													<td>{meditation.title}</td>
													<td>{meditation.image && (<img src={`${serverURL}` + meditation.image} alt={meditation.title} style={{ width: "100px" }} />)}</td>
													<td>{meditation.music.map((m)=>m.title + ", ")}</td>
													<td><button className="btn btn-sm btn-primary" onClick={handleEdit}>Edit</button>
														<button className="btn btn-sm btn-danger" onClick={handleDelete}>Delete</button></td>
												</tr>
											}))}
										</tbody>
									</table>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}
