import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function EditExercise() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [allBodyparts, setAllBodyparts] = useState([]);
  const [videoPath, setVideoPath] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("free");

  // const [unassignedBodyparts, setUnassignedBodyparts] = useState([]);
  // const [showAssigned, setShowAssigned] = useState(false);
  // const [showAddNew, setShowAddNew] = useState(false);

  const [exercise, setExercise] = useState({
    title: "",
    image: "",
    video: "",
    bodypart: [],
    description: "",
    reps: "",
    sets: "",
    rest: "",
    level: "",
    price: "",
  });

  useEffect(() => {
    async function fetchExercise() {
      try {
        const response = await axios.get(`${serverURL}exercise/${id}`, {
          headers: {
            authorization: token,
          },
        });
        const exerciseData = response.data;
        setExercise({
          ...exerciseData,
          bodypart: exerciseData.bodypart.map((part) => part._id),
        });
        setImagePath(response.data.image);
        setVideoPath(response.data.video);
        // setAssignedBodyparts(exerciseData.bodypart.map((part) => part._id));
        setSelectedPrice(exerciseData.price)
      } catch (error) {
        console.log(error);
      }
    }

    fetchExercise();
  }, [token, id]);

  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
    setExercise({ ...exercise, price: event.target.value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setExercise({
          ...exercise,
          image: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleVideoUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("video", selectedFile);

    axios
      .post(`${serverURL}uploadvideo`, formD)
      .then((response) => {
        setVideoPath(response.data.videoPath);
        setExercise({
          ...exercise,
          video: response.data.videoPath,
        });
        console.log("Video uploaded:", response.data.videoPath);
      })
      .catch((error) => {
        console.error("Error uploading video:", error);
      });
  };

  useEffect(() => {
    const fetchBodypart = async () => {
      try {
        const response = await axios.get(`${serverURL}bodypart`);
        setAllBodyparts(response.data);
      } catch (error) {
        console.error("Error fetching Bodyparts:", error);
      }
    };

    fetchBodypart();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const selectedValues = [...exercise.bodypart];

      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setExercise((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      setExercise((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${serverURL}exercise/${id}`, exercise, {
        headers: {
          authorization: token,
        },
      });

      Swal.fire("Congrats", "Exercise updated successfully!", "success").then(
        (result) => {
          window.location.href = "/exercise";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const isSelected = (array, id) => array.includes(id.toString());

  return (
    <div>
      <form onSubmit={handleSubmit} className="exercise-form">
        <br />
        <div>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={exercise.title}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Image:</label>
          <input
            type="text"
            name="image"
            value={exercise.image}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <div>
            {imagePath && (
              <img
                src={serverURL + imagePath}
                style={{ height: "200px" }}
                alt="Uploaded"
              />
            )}
          </div>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
        </div>
        <div className="form-group">
          <label htmlFor="videoUrl">Video:</label>
          <div>
            {videoPath && (
              <video src={serverURL + videoPath} height={270} alt="Uploaded" />
            )}
          </div>
          <input
            type="file"
            id="video"
            name="video"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleVideoUpload}>Upload Video</button>
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            className="form-group"
            type="textarea"
            name="description"
            value={exercise.description}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Reps:</label>
          <input
            type="text"
            name="reps"
            placeholder="Only enter numbers"
            value={exercise.reps}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Sets:</label>
          <input
            type="text"
            name="sets"
            placeholder="Only enter numbers"
            value={exercise.sets}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Rest:</label>
          <input
            type="text"
            name="rest"
            placeholder="Only enter numbers"
            value={exercise.rest}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Level:</label>
          <input
            type="text"
            name="level"
            value={exercise.level}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className="form-group">
          <label>Price:</label>
          <div>
            <label className="p-3">
              <input
                type="radio"
                value="free"
                checked={selectedPrice === "free"}
                onChange={handlePriceChange}
              />
              Free
            </label>

            <label className="p-3">
              <input
                type="radio"
                value="premium"
                checked={selectedPrice === "premium"}
                onChange={handlePriceChange}
              />
              Premium
            </label>
          </div>
          {/* <input type="text" name="price" className="form-input" value={stretching.price} onChange={handleChange} /> */}
        </div>
        <br />
        {
          <div>
            <table className="exercise-table">
              <thead>
                <tr>
                  <th>Bodyparts</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allBodyparts.map((part) => {
                  if (part) {
                    return (
                      <tr key={part._id}>
                        <td>{part.title}</td>
                        <td>
                          <input
                            type="checkbox"
                            name="bodypart"
                            value={part._id}
                            checked={isSelected(exercise.bodypart, part._id)}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        }
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
