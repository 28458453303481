import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { serverURL } from "../serverURL";
import getCookie from "./getCookie";
import "../App.css";

export default function ShowMeditationMusic() {
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);
  const navigate = useNavigate();

  const [meditationMusic, setmeditationMusic] = useState([]);

  useEffect(() => {
    async function getResults() {
      try {
        const response = await axios.get(`${serverURL}meditationMusic`);
        setmeditationMusic(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getResults();
  }, []);

  return (
    <>
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered">
                    <thead className="bs thead-dark">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Music</th>
                      </tr>
                    </thead>
                    <tbody>
                      {meditationMusic.map((music, index) => {
                        const handleEdit = () => {
                          navigate(`/meditationmusic/edit/id=${music._id}`);
                        };
                        const handleDelete = () => {
                          navigate(`/meditationMusic/delete/id=${music._id}`);
                        };
                        return (
                          <tr key={music._id}>
                            <td>{index + 1}</td>
                            <td>{music.title}</td>
                            <td>
                              {music.music && (
                                <audio controls>
                                  <source src={`${serverURL}${music.music}`} />
                                </audio>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={handleEdit}
                              >
                                Edit
                              </button>
                              <button
                                  className="btn btn-sm btn-danger"
                                  onClick={handleDelete}
                                >
                                  Delete
                                </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
