import "./App.css";
import Home from "./components/Home";
import EditUser from "./components/Edituser";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DeleteUser from "./components/DeleteUser";
import AddUser from "./components/AddUser";
import ShowExercise from "./components/ShowExercise";
import EditExercise from "./components/EditExercise";
import DeleteExercise from "./components/DeleteExercise";
import AddExercise from "./components/AddExercise";
import ShowStretching from "./components/ShowStretching";
import EditStretching from "./components/EditStretching";
import DeleteStretching from "./components/DeleteStretching";
import AddStretching from "./components/AddStretching";
import ShowBodypart from "./components/ShowBodypart";
import AddBodypart from "./components/AddBodypart";
import ShowMeditation from "./components/ShowMeditation";
import EditMeditation from "./components/EditMeditation";
import AddMeditation from "./components/AddMeditation";
import DeleteMeditation from "./components/DeleteMeditation";
import ShowMeal from "./components/ShowMeal";
import EditMeal from "./components/EditMeal";
import DeleteMeal from "./components/DeleteMeal";
import AddMeal from "./components/AddMeal";
import ShowHabit from "./components/ShowHabit";
import EditHabit from "./components/EditHabit";
import DeleteHabit from "./components/DeleteHabit";
import AddHabit from "./components/AddHabit";
import Login from "./components/Login";
import EditBodypart from "./components/EditBodypart";
import ShowDo from "./components/ShowDo";
import EditDo from "./components/EditDo";
import DeleteDo from "./components/DeleteDo";
import AddDo from "./components/AddDo";
import Logout from "./components/Logout";
import ShowHabitType from "./components/ShowHabitType";
import EditHabitType from "./components/EditHabitType";
import DeleteHabitType from "./components/DeleteHabitType";
import AddHabitType from "./components/AddHabitType";
import ShowDoType from "./components/ShowDoType";
import EditDoType from "./components/EditDoType";
import DeleteDoType from "./components/DeleteDoType";
import AddDoType from "./components/AddDoType";
import AddMealType from "./components/AddMealType";
import EditMealType from "./components/EditMealType";
import ShowMealType from "./components/ShowMealType";
import DeleteMealType from "./components/DeleteMealType";
import ShowMeditationMusic from "./components/ShowMeditationMusic";
import AddMeditationMusic from "./components/AddMeditationMusic";
import DeleteMeditationMusic from "./components/DeleteMeditationMusic";
import EditMeditationMusic from "./components/EditmeditationMusic";
import ShowProduct from "./components/ShowProduct";
import EditProduct from "./components/EditProduct";
import DeleteProduct from "./components/DeleteProduct";
import AddProduct from "./components/AddProduct";
import EditOneStretching from "./components/EditOneStretching";
import ShowOneStretching from "./components/showOneStretching";
import AddOneStretching from "./components/AddOneStretching";
import DeleteOneStretching from "./components/DeleteOneStretching";
import ShowProductType from "./components/ShowProductType";
import EditProductType from "./components/EditProductType";
import DeleteProductType from "./components/DeleteProductType";
import AddProductType from "./components/AddProductType";
import AddArticle from "./components/AddArticle";
import ShowArticles from "./components/ShowArticles";
import EditArticle from "./components/EditArticle";
import User from "./components/User";
import DeleteArticle from "./components/DeleteArticle";
import ShowTestimonial from "./components/ShowTestimonial";
import AddTestimonial from "./components/AddTestimonial";
import EditTestimonial from "./components/EditTestimonial";
import DeleteTestimonial from "./components/DeleteTestimonial";
import ShowYoga from "./components/ShowYoga";
import AddYoga from "./components/AddYoga";
import EditYoga from "./components/EditYoga";
import DeleteYoga from "./components/DeleteYoga";
import AddBanner from "./components/AddBanner";
import EditBanner from "./components/EditBanner";
import DeleteBanner from "./components/DeleteBanner";
import ShowBanners from "./components/ShowBanner";
import GDCoins from "./components/GdCoins";
import Referral from "./components/Referral";
import AddCoupon from "./components/AddCoupon"; // Import AddCoupon component
import ShowCoupons from "./components/ShowCoupons"; // Import ShowCoupons component

import ReedemRequests from "./components/ReedemRequests";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/exercise" exact element={<ShowExercise />} />
        <Route
          path="/exercise/edit/:id"
          exact
          element={<EditExercise />}
        ></Route>
        <Route
          path="/exercise/delete/:id"
          exact
          element={<DeleteExercise />}
        ></Route>
        <Route path="/exerciseform" exact element={<AddExercise />}></Route>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/gd-coins" exact element={<GDCoins />}></Route>
        <Route path="/referral" exact element={<Referral />}></Route>
        <Route
          path="/reedem-requests"
          exact
          element={<ReedemRequests />}
        ></Route>
        <Route path="/user/edit/:id" exact element={<EditUser />}></Route>
        <Route path="/user/delete/:id" exact element={<DeleteUser />}></Route>
        <Route path="/userform" exact element={<AddUser />}></Route>
        <Route path="/stretching" exact element={<ShowStretching />} />
        <Route
          path="/stretching/edit/:id"
          exact
          element={<EditStretching />}
        ></Route>
        <Route
          path="/stretching/delete/:id"
          exact
          element={<DeleteStretching />}
        ></Route>
        <Route path="/stretchingform" exact element={<AddStretching />}></Route>
        <Route path="/bodypart" exact element={<ShowBodypart />} />
        <Route
          path="/bodypart/edit/:id"
          exact
          element={<EditBodypart />}
        ></Route>
        <Route path="/bodypartform" exact element={<AddBodypart />}></Route>
        <Route path="/meditation" exact element={<ShowMeditation />} />
        <Route
          path="/meditation/edit/:id"
          exact
          element={<EditMeditation />}
        ></Route>
        <Route
          path="/meditation/delete/:id"
          exact
          element={<DeleteMeditation />}
        ></Route>
        <Route path="/meditationform" exact element={<AddMeditation />}></Route>
        <Route path="/meal" exact element={<ShowMeal />} />
        <Route path="/meal/edit/:id" exact element={<EditMeal />}></Route>
        <Route path="/meal/delete/:id" exact element={<DeleteMeal />}></Route>
        <Route path="/mealform" exact element={<AddMeal />}></Route>
        <Route path="/habit" exact element={<ShowHabit />} />
        <Route path="/habit/edit/:id" exact element={<EditHabit />}></Route>
        <Route path="/habit/delete/:id" exact element={<DeleteHabit />}></Route>
        <Route path="/habitform" exact element={<AddHabit />}></Route>
        <Route path="/do" exact element={<ShowDo />} />
        <Route path="/Do/edit/:id" exact element={<EditDo />}></Route>
        <Route path="/Do/delete/:id" exact element={<DeleteDo />}></Route>
        <Route path="/doform" exact element={<AddDo />}></Route>
        <Route path="/logout" exact element={<Logout />}></Route>
        <Route path="/habittype" exact element={<ShowHabitType />} />
        <Route
          path="/habittype/edit/:id"
          exact
          element={<EditHabitType />}
        ></Route>
        <Route
          path="/habittype/delete/:id"
          exact
          element={<DeleteHabitType />}
        ></Route>
        <Route path="/habittypeform" exact element={<AddHabitType />}></Route>
        <Route path="/dotype" exact element={<ShowDoType />} />
        <Route path="/dotype/edit/:id" exact element={<EditDoType />}></Route>
        <Route
          path="/dotype/delete/:id"
          exact
          element={<DeleteDoType />}
        ></Route>
        <Route path="/dotypeform" exact element={<AddDoType />}></Route>
        <Route path="/mealtypes" exact element={<ShowMealType />} />
        <Route
          path="/mealtypes/edit/:id"
          exact
          element={<EditMealType />}
        ></Route>
        <Route
          path="/mealtypes/delete/:id"
          exact
          element={<DeleteMealType />}
        ></Route>
        <Route path="/mealtypesform" exact element={<AddMealType />}></Route>
        <Route
          path="/meditationmusic"
          exact
          element={<ShowMeditationMusic />}
        />
        <Route
          path="/meditationmusic/edit/:id"
          exact
          element={<EditMeditationMusic />}
        ></Route>
        <Route
          path="/meditationmusic/delete/:id"
          exact
          element={<DeleteMeditationMusic />}
        ></Route>
        <Route
          path="/meditationmusicform"
          exact
          element={<AddMeditationMusic />}
        ></Route>

        <Route path="/onestretching" exact element={<ShowOneStretching />} />
        <Route
          path="/onestretching/edit/:id"
          exact
          element={<EditOneStretching />}
        ></Route>
        <Route
          path="/onestretchingform"
          exact
          element={<AddOneStretching />}
        ></Route>
        <Route
          path="/onestretching/delete/:id"
          exact
          element={<DeleteOneStretching />}
        ></Route>
        <Route path="/user" exact element={<User />}></Route>

        <Route path="/product" exact element={<ShowProduct />} />
        <Route path="/product/edit/:id" exact element={<EditProduct />}></Route>
        <Route
          path="/product/delete/:id"
          exact
          element={<DeleteProduct />}
        ></Route>
        <Route path="/productform" exact element={<AddProduct />}></Route>

        <Route path="/producttype" exact element={<ShowProductType />} />
        <Route
          path="/producttype/edit/:id"
          exact
          element={<EditProductType />}
        ></Route>
        <Route
          path="/producttype/delete/:id"
          exact
          element={<DeleteProductType />}
        ></Route>
        <Route
          path="/producttypeform"
          exact
          element={<AddProductType />}
        ></Route>

        <Route path="/articles" exact element={<ShowArticles />} />
        <Route path="/addarticle" exact element={<AddArticle />}></Route>
        <Route
          path="/articles/edit/:id"
          exact
          element={<EditArticle />}
        ></Route>
        <Route
          path="/articles/delete/:id"
          exact
          element={<DeleteArticle />}
        ></Route>

        <Route path="/testimonial" exact element={<ShowTestimonial />} />
        <Route path="/addtestimonial" exact element={<AddTestimonial />} />
        <Route
          path="/testmonial/edit/:id"
          exact
          element={<EditTestimonial />}
        ></Route>
        <Route
          path="/testmonial/delete/:id"
          exact
          element={<DeleteTestimonial />}
        ></Route>

        <Route path="/yoga" exact element={<ShowYoga />}></Route>
        <Route path="/addyoga" exact element={<AddYoga />}></Route>
        <Route path="/yoga/edit/:id" exact element={<EditYoga />}></Route>
        <Route path="/yoga/delete/:id" exact element={<DeleteYoga />}></Route>

        <Route path="/banner" exact element={<ShowBanners/>}></Route>
        <Route path="/addBanner" exact element={<AddBanner />}></Route>
        <Route path="/banner/edit/:id" exact element={<EditBanner />}></Route>
        <Route
          path="/banner/delete/:id"
          exact
          element={<DeleteBanner/>}
        ></Route>
        <Route path="/coupon" exact element={<ShowCoupons />} /> {/* Add this line */}
        <Route path="/coupon/add" exact element={<AddCoupon />} /> {/* Add this line */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
