import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "./Style.css";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddHabit() {
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    type: [],
    image: "",
    description: ""
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formD = new FormData();
    formD.append('image', selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setFormData((prevData) => ({
          ...prevData,
          image: response.data.imagePath,
        }));
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const selectedValues = [...formData.type];

      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        type: selectedValues,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    axios.get(`${serverURL}habittypes`)
      .then(response => {
        setOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${serverURL}habit`, formData, {
        headers: {
            authorization: token,
        },
    });;
      Swal.fire('Congrats', 'Habit added successfully!', 'success').then(result => {
        window.location.href = '/habit';
      });
    } catch (error) {
      console.error("Error adding habit:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="habit-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Habit Type</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {options.map(option => (
                <tr key={option._id}>
                  <td>{option.type_title}</td>
                  <td>
                    <input
                      type="checkbox"
                      id={option._id}
                      name="type"
                      value={option._id}
                      checked={formData.type.includes(option._id)}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL + imagePath} style={{height: "200px"}} alt="Uploaded" />}
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-group"
          />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}
