import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import './Style.css'
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function AddStretching() {

  const token = getCookie('token')
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        if (response.status !== 200) {
          window.location.href = '/login';
        }
      } catch (error) {
        window.location.href = '/login';
      }
    }
    getUser()
  }, [token]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState('free');

  const [bodyParts, setBodyParts] = useState([])
  const [options, setOptions] = useState([]);
  const [imagePath, setImagePath] = useState('');
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    bodypart: [],
    description: "",
    level: "",
    price: selectedPrice,
    duration: "",
    oneStretching: []
  });

  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
    setFormData({ ...formData, price: event.target.value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault()
    const formD = new FormData();
    formD.append('image', selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setFormData({
          ...formData,
          image: response.data.imagePath,
        });
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };

  useEffect(() => {
    const fetchBodyParts = async () => {
      try {
        const response = await axios.get(`${serverURL}bodypart`);
        setBodyParts(response.data);
      } catch (error) {
        console.error("Error fetching body parts:", error);
      }
    };

    fetchBodyParts();
  }, []);

  useEffect(() => {
    const fetchOneStretching = async () => {
      try {
        const response = await axios.get(`${serverURL}onestretching`);
        setOptions(response.data);
      } catch (error) {
        console.error("Error fetching stretching options:", error);
      }
    };

    fetchOneStretching();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const selectedValues = [...formData.bodypart];

      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleTypeChange = (e) => {
    const { name, value, checked } = e.target;
    const selectedValues = formData.oneStretching;
    if (checked) {
      selectedValues.push(value);
    } else {
      const index = selectedValues.indexOf(value);
      if (index > -1) {
        selectedValues.splice(index, 1);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${serverURL}stretching`, formData, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire('Congrats', 'Stretching added successfully!', 'success').then(result => {
        window.location.href = '/stretching'
      })
    } catch (error) {
      console.error("Error adding exercise:", error);
    }
  };

  return (
    <div>
      <br />
      <form onSubmit={handleSubmit} className="exercise-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
            type="file"
            id="image"
            name="image"

            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL + imagePath} style={{ height: "200px" }} alt="Uploaded" />}
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-group"
          />
        </div>
        <div className="form-group">
          <label htmlFor="level">Level:</label>
          <input
            type="text"
            id="level"
            name="level"
            placeholder="Only enter numbers"
            value={formData.level}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Price:</label>
          <div>
            <label>
              <input
                type="radio"
                value="free"
                checked={selectedPrice === 'free'}
                onChange={handlePriceChange}
              />
              Free
            </label>

            <label>
              <input
                type="radio"
                value="premium"
                checked={selectedPrice === 'premium'}
                onChange={handlePriceChange}
              />
              Premium
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="duration">Duration:</label>
          <input
            type="text"
            id="duration"
            name="duration"
            placeholder="Only enter numbers"
            value={formData.duration}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="exercise">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Exercise Group: </th>
                <th>Select</th>
              </tr>
            </thead>
            {options.map(option => (
              <tr key={option._id}>
                <td>{option.title}</td>
                <td>
                  <input
                    type="checkbox"
                    id={option._id}
                    name="exercise"
                    value={option._id}
                    checked={formData.oneStretching.includes(option._id)}
                    onChange={handleTypeChange}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="form-group">
          <label>Body Parts:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Body Part</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {bodyParts.map((part) => (
                <tr key={part._id}>
                  <td>{part.title}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="bodypart"
                      value={part._id}
                      checked={formData.bodypart.includes(part._id)}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}
