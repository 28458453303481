import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function EditHabit() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [habit, setHabit] = useState({
    title: "",
    type: "",
    image: "",
    description: "",
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchHabit() {
      try {
        const response = await axios.get(`${serverURL}habit/${id}`);
        setHabit(response.data);
        setImagePath(response.data.image);
      } catch (error) {
        console.log(error);
      }
    }

    fetchHabit();
  }, [id]);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setHabit({
          ...habit,
          image: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${serverURL}habittypes`)
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHabit((prevHabit) => ({
      ...prevHabit,
      [name]: value,
    }));
  };

  const isTypeChecked = (optionId) => {
    return optionId === habit.type._id;
  };

  const handleTypeChange = (e) => {
    const optionId = e.target.value;
    const isChecked = e.target.checked;

    setHabit((prevHabit) => ({
      ...prevHabit,
      type: isChecked ? { _id: optionId } : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${serverURL}habit/${id}`, habit, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire("Congrats", "Habit updated successfully!", "success").then(
        (result) => {
          window.location.href = "/habit";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="edit-bodypart-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={habit.title}
            readOnly
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && (
            <img
              src={serverURL + imagePath}
              style={{ height: "200px" }}
              alt="Uploaded"
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Habit Type</th>
                <th>Select</th>
              </tr>
            </thead>
            {options.map((option) => (
              <tr key={option._id}>
                <td>{option.type_title}</td>
                <td>
                  <input
                    type="checkbox"
                    id={option._id}
                    name="type"
                    value={option._id}
                    checked={isTypeChecked(option._id)}
                    onChange={handleTypeChange}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="image">Image:</label>
          <input
            type="text"
            id="image"
            name="image"
            value={habit.image}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={habit.description}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}
