import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function EditMeditation() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [music, setMusic] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState("free");
  const [meditation, setMeditation] = useState({
    title: "",
    image: "",
    music: [],
    description: "",
    level: "",
    duration: "",
    price: "",
  });

  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
    setMeditation({ ...meditation, price: event.target.value });
  };

  useEffect(() => {
    async function fetchMeditation() {
      try {
        const response = await axios.get(`${serverURL}meditation/${id}`);
        const meditationData = response.data;
        setMeditation({
          ...meditationData,
          music: response.data.music.map((part) => part._id),
        });
        setSelectedPrice(response.data.price);
      } catch (error) {
        console.log(error);
      }
    }

    fetchMeditation();
  }, [id]);

  useEffect(() => {
    const fetchMusic = async () => {
      try {
        const response = await axios.get(`${serverURL}meditationmusic`);
        setMusic(response.data);
      } catch (error) {
        console.error("Error fetching body parts:", error);
      }
    };

    fetchMusic();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setMeditation({
          ...meditation,
          image: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const selectedValues = [...meditation.music];

      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setMeditation((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      setMeditation((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(meditation)
      await axios.put(`${serverURL}meditation/${id}`, meditation, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire("Congrats", "Meditation updated successfully!", "success").then(
        (result) => {
          window.location.href = "/meditation";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="habit-form">
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={meditation.title}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && (
            <img
              src={serverURL + imagePath}
              style={{ height: "200px" }}
              alt="Uploaded"
            />
          )}
        </div>
        <div>
          <label>Image:</label>
          <input
            type="text"
            name="image"
            value={meditation.image}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Level:</label>
          <input
            type="text"
            name="level"
            placeholder="Only enter numbers"
            value={meditation.level}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Price:</label>
          <div>
            <label>
              <input
                type="radio"
                value="free"
                checked={selectedPrice === "free"}
                onChange={handlePriceChange}
              />
              Free
            </label>

            <label>
              <input
                type="radio"
                value="premium"
                checked={selectedPrice === "premium"}
                onChange={handlePriceChange}
              />
              Premium
            </label>
          </div>
          {/* <input type="text" name="price" value={meditation.price} onChange={handleChange} /> */}
        </div>
        <br />
        <div>
          <label>Duration:</label>
          <input
            type="text"
            name="duration"
            placeholder="Only enter numbers"
            value={meditation.duration}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Description:</label>
          <textarea
            className="form-group"
            type="text"
            name="description"
            value={meditation.description}
            onChange={handleChange}
          />
        </div>
        <br />
        <br />
        <br />
        <div className="form-group">
          <label>Meditation Music:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Meditation Music</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {music.map((part) => (
                <tr key={part._id}>
                  <td>{part.title}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="music"
                      value={part._id}
                      checked={meditation.music.includes(part._id)}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
