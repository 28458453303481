import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import getCookie from "./getCookie";
import axios from "axios";
import { serverURL } from "../serverURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePay } from "@fortawesome/fontawesome-free";

import { Button, Modal, Table, Timeline } from "flowbite-react";
import moment from "moment";
import { QRCode } from "react-qrcode";
const ReedemRequests = () => {
  const token = getCookie("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };

    const getReedemRequests = async () => {
      try {
        const response = await axios.get(`${serverURL}coins/reedem-request`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        let data = response.data;
        setData(data || []);
      } catch (error) {}
    };

    // getUser();
    getReedemRequests();
  }, [token]);

  const onViewItem = (dta) => {
    setModalOpen(true);
    const upiUri = `upi://pay?pa=${dta.upiId}&pn=${encodeURIComponent(
      dta.name
    )}&tn=Reedem GD Coins&am=${dta.amount}&cu=INR`;
    dta.upiUri = upiUri;
    setItem(dta);
  };

  const onClose = () => {
    setItem(null);
    setModalOpen(false);
  };

  return (
    <div className="dashboard d-flex">
      <div>
        <Sidebar />
      </div>
      <div className="p-3 w-full flex max-h-screen overflow-scroll">
        {data && data.length > 0 && (
          <Table striped>
            <Table.Head>
              <Table.HeadCell>S.NO</Table.HeadCell>
              <Table.HeadCell>UID</Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Phone</Table.HeadCell>
              <Table.HeadCell>upiId</Table.HeadCell>
              <Table.HeadCell>Scan to Pay</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {data.map((item, i) => {
                let { name, email, phone, upiId, uid } = item;
                return (
                  <Table.Row className="bg-white">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900">
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>{uid}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>{phone}</Table.Cell>
                    <Table.Cell>{upiId}</Table.Cell>
                    <Table.Cell
                      className="cursor-pointer"
                      onClick={() => onViewItem(item)}
                    >
                      <img
                        alt="..."
                        src="/upi.png"
                        className="md:w-[100px] md:h-[100px] w-[50px] h-[50px] object-cover"
                      />
                      {/* <FontAwesomeIcon color="green" icon={faGooglePay} /> */}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>

      <Modal show={modalOpen} onClose={onClose}>
        <Modal.Body>
          {item && (
            <>
              {item.upiUri && (
                <div className="flex items-center justify-center">
                  <QRCode value={item.upiUri} />
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => window.open(item.upiUri, "_blank")}
            className="mx-auto"
          >
            OPEN FROM HERE
          </Button>

          <Button color="gray" onClick={onClose} className="mx-auto">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReedemRequests;
