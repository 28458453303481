import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "./Style.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function EditMeditationMusic() {
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [musicPath, setMusicPath] = useState("");
  const [meditationMusic, setMeditationMusic] = useState({
    title: "",
    music: "",
  });

  useEffect(() => {
    async function fetchMeditationMusic() {
      try {
        const response = await axios.get(`${serverURL}meditationMusic/${id}`);
        setMeditationMusic(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchMeditationMusic();
  }, [id]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("music", selectedFile);

    axios
      .post(`${serverURL}uploadmusic`, formData)
      .then((response) => {
        setMusicPath(response.data.musicPath);
        setMeditationMusic({
          ...meditationMusic,
          music: response.data.musicPath,
        });
        console.log("Music uploaded:", response.data.musicPath);
      })
      .catch((error) => {
        console.error("Error uploading music:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMeditationMusic((prevMeditationMusic) => ({
      ...prevMeditationMusic,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(meditationMusic);
      await axios.put(`${serverURL}meditationMusic/${id}`, meditationMusic, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire(
        "Congrats",
        "Meditation music updated successfully!",
        "success"
      ).then((result) => {
        window.location.href = "/meditationMusic";
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="edit-meditationMusic-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={meditationMusic.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="music">Music:</label>
          <div>{musicPath && <audio controls src={serverURL+musicPath}></audio>}</div>
          <input
            type="file"
            id="music"
            name="music"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Song</button>          
        </div>
        <div className="form-group">
          <label htmlFor="musicUrl">Music URL:</label>
          <input
            type="text"
            id="musicUrl"
            name="music"
            value={meditationMusic.music}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}
