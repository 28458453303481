import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";
import Swal from "sweetalert2";

export default function User() {
  const [sortOrderName, setSortOrderName] = useState("asc");
  const [sortOrderAge, setSortOrderAge] = useState("asc");
  const [sortOrderHeight, setSortOrderHeight] = useState("asc");
  const [sortOrderCreationTime, setSortOrderCreationTime] = useState("asc");
  const [sortOrderWeight, setSortOrderWeight] = useState("asc");
  const [sortOrderPlan, setSortOrderPlan] = useState("asc");
  const [allBodyparts, setAllBodyparts] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [stretching, setStretching] = useState([]);
  const [meditation, setMeditation] = useState([]);
  const [assignedExercises, setAssignedExercises] = useState([]);
  const [unassignedExercises, setUnassignedExercises] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [assignedStretchings, setAssignedStretchings] = useState([]);
  const [unassignedStretchings, setUnassignedStretchings] = useState([]);
  const [showAddNewStretchings, setShowAddNewStretchings] = useState(false);
  const [assignedMeditations, setAssignedMeditations] = useState([]);
  const [unassignedMeditations, setUnassignedMeditations] = useState([]);
  const [showAddNewMeditations, setShowAddNewMeditations] = useState(false);
  const [mealTypes, setMealTypes] = useState([]);
  const [meals, setMeals] = useState([]);
  const [assignedMeals, setAssignedMeals] = useState([]);
  const [unassignedMeals, setUnassignedMeals] = useState([]);
  const [showAddNewMeals, setShowAddNewMeals] = useState(false);

  function convertArrayToCSV(data, filename) {
    console.log(data);
    const csvContent = generateCSVContent(data);
    const csvData = new Blob([csvContent], { type: "text/csv" });

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(csvData);
    downloadLink.setAttribute("download", filename);

    downloadLink.click();
  }

  function generateCSVContent(data) {
    const allUsers = Object.keys(data);
    const excludedKeys = [
      "exercise",
      "stretching",
      "meditation",
      "meal",
      "signUpData",
      "subscription",
    ];
    const nonExcludedKeys = Object.keys(data[allUsers[0]]).filter(
      (key) => !excludedKeys.includes(key)
    );
    const allHeaders = [
      "'ID",
      ...nonExcludedKeys,
      "Timings",
      "Habit",
      "Name",
      "Email",
      "Mobile",
      "Password",
      "Checked",
    ];
    const headerRow = allHeaders.join(",") + "\n";
    const rows = allUsers.map((id) => {
      const rowData = data[id];
      const nonExcludedData = nonExcludedKeys.map((key) => {
        const value = rowData[key];
        return Array.isArray(value) ? value.join(" ") : value;
      });
      const consultation = rowData["consultation"];
      const timings = consultation && consultation ? rowData["timings"] : "";
      const habit = rowData["habits"];
      const habitValue = Array.isArray(habit) ? habit.join(" ") : "";
      const signUpData = rowData["signUpData"];
      const signUpName = signUpData
        ? signUpData.Name
          ? signUpData.Name
          : signUpData.FirstName + " " + signUpData.LastName
        : "";
      const signUpEmail = signUpData ? signUpData.Email : "";
      const signUpMobile = signUpData
        ? signUpData.Mobile
          ? signUpData.Mobile
          : signUpData.Phone
        : "";
      const signUpPassword = signUpData ? signUpData.Password : "";
      const signUpChecked = signUpData ? signUpData.checked : "";

      return `${id},${nonExcludedData.join(",")},${
        timings ? `"${timings.join(", ")}"` : ""
      },${habitValue},${signUpName},${signUpEmail},${signUpMobile},${signUpPassword},${signUpChecked}`;
    });

    return headerRow + rows.join("\n");
  }

  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);
  const handleUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAddNew(!showAddNew);
  };

  const handleStretchingUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAddNewStretchings(!showAddNewStretchings);
  };

  const handleMeditationUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAddNewMeditations(!showAddNewMeditations);
  };

  const handleMealUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAddNewMeals(!showAddNewMeals);
  };
  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const response = await axios.get(`${serverURL}exercise`);
        const allExercises = response.data;
        setExercise(allExercises);
        const unassignedExercises = allExercises.filter(
          (part) => !assignedExercises.includes(part._id.toString())
        );
        setUnassignedExercises(unassignedExercises);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
    };

    fetchExercise();
  }, [assignedExercises]);

  useEffect(() => {
    const fetchBodypart = async () => {
      try {
        const response = await axios.get(`${serverURL}bodypart`);
        setAllBodyparts(response.data);
      } catch (error) {
        console.error("Error fetching Bodyparts:", error);
      }
    };

    fetchBodypart();
  }, []);

  useEffect(() => {
    const fetchStretching = async () => {
      try {
        const response = await axios.get(`${serverURL}stretching`);
        const allStretchings = response.data;
        setStretching(allStretchings);
        const unassignedStretchings = allStretchings.filter(
          (part) => !assignedStretchings.includes(part._id.toString())
        );
        setUnassignedStretchings(unassignedStretchings);
      } catch (error) {
        console.error("Error fetching stretchings:", error);
      }
    };

    fetchStretching();
  }, []);

  useEffect(() => {
    const fetchMeditation = async () => {
      try {
        const response = await axios.get(`${serverURL}meditation`);
        const allMeditations = response.data;
        setMeditation(allMeditations);
        const unassignedMeditations = allMeditations.filter(
          (part) => !assignedMeditations.includes(part._id.toString())
        );
        setUnassignedMeditations(unassignedMeditations);
      } catch (error) {
        console.error("Error fetching meditations:", error);
      }
    };

    fetchMeditation();
  }, []);

  useEffect(() => {
    const fetchMealTypes = async () => {
      try {
        const response = await axios.get(`${serverURL}mealtypes`);
        setMealTypes(response.data);
      } catch (error) {
        console.error("Error fetching Bodyparts:", error);
      }
    };

    fetchMealTypes();
  }, []);

  useEffect(() => {
    const fetchMeal = async () => {
      try {
        const response = await axios.get(`${serverURL}meal`);
        const allMeals = response.data;
        setMeals(allMeals);
        const unassignedMeals = allMeals.filter(
          (part) => !assignedMeals.includes(part._id.toString())
        );
        setUnassignedMeals(unassignedMeals);
      } catch (error) {
        console.error("Error fetching Meals:", error);
      }
    };

    fetchMeal();
  }, []);

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [phoneQuery, setPhoneQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [planQuery, setPlanQuery] = useState("");
  const [minAgeQuery, setMinAgeQuery] = useState("");
  const [maxAgeQuery, setMaxAgeQuery] = useState("");
  const [genderQuery, setGenderQuery] = useState("");
  const [categoryQuery, setCategoryQuery] = useState("");
  const [users, setUsers] = useState([]);
  const isSelected = (array, id) => array.includes(id.toString());
  const handleSelectChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "exercise") {
      if (checked) {
        setAssignedExercises((prevExercises) => [...prevExercises, value]);

        setUnassignedExercises((prevExercises) =>
          prevExercises.filter((exercise) => exercise._id !== value)
        );
      } else {
        setAssignedExercises((prevExercises) =>
          prevExercises.filter((exerciseId) => exerciseId !== value)
        );

        const unassignedPart = exercise.find((part) => part._id === value);
        if (unassignedPart) {
          setUnassignedExercises((prevExercises) => [
            ...prevExercises,
            unassignedPart,
          ]);
        }
        //  setUnassignedExercises((prevExercises) => [
        //    ...prevExercises,
        //    exercise.find((part) => part._id === value)
        //  ]);
      }
    }

    if (name === "stretching") {
      if (checked) {
        setAssignedStretchings((prevStretchings) => [
          ...prevStretchings,
          value,
        ]);
      } else {
        setAssignedStretchings((prevStretchings) =>
          prevStretchings.filter((stretchingId) => stretchingId !== value)
        );
      }
    }

    if (name === "meditation") {
      if (checked) {
        setAssignedMeditations((prevMeditations) => [
          ...prevMeditations,
          value,
        ]);
      } else {
        setAssignedMeditations((prevMeditations) =>
          prevMeditations.filter((meditationId) => meditationId !== value)
        );
      }
    }

    if (name === "meal") {
      if (checked) {
        setAssignedMeals((prevMeals) => [...prevMeals, value]);
      } else {
        setAssignedMeals((prevMeals) =>
          prevMeals.filter((mealId) => mealId !== value)
        );
      }
    }
  };

  useEffect(() => {
    async function getResults() {
      try {
        axios
          .get(`${serverURL}user`, {
            headers: {
              authorization: localStorage.getItem("token"),
            },
          })
          .then((d) => {
            setUsers(d.data);
            // console.log(d.data)
          });
      } catch (error) {}
    }
    getResults();
  }, []);
  // console.log("users is now = "+ users)
  let filteredUsers = Object.keys(users).filter((userId) => {
    const user = users[userId];
    const minAge = parseInt(minAgeQuery);
    const maxAge = parseInt(maxAgeQuery);
    if (user.signUpData.Name) {
      // console.log(user.metadata.createdAt)
      return (
        user.signUpData.Name.toLowerCase().includes(
          searchQuery.toLowerCase()
        ) &&
        user.subscription.subscriptionStatus
          .toLowerCase()
          .startsWith(planQuery.toLowerCase()) &&
        user.signUpData.Mobile.toString().includes(phoneQuery) &&
        user.signUpData.Email.toLowerCase().includes(
          emailQuery.toLowerCase()
        ) &&
        (isNaN(minAge) || user.selectedAge >= minAge) &&
        (isNaN(maxAge) || user.selectedAge <= maxAge) &&
        (!genderQuery ||
          user.selectedGender.toLowerCase() === genderQuery.toLowerCase()) &&
        user.category &&
        user.category.toLowerCase().includes(categoryQuery.toLowerCase())
      );
    } else if (user.signUpData.FirstName) {
      return (
        user.signUpData.FirstName.toLowerCase().includes(
          searchQuery.toLowerCase()
        ) &&
        user.subscription.subscriptionStatus
          .toLowerCase()
          .startsWith(planQuery.toLowerCase()) &&
        user.signUpData.Phone.toString().includes(phoneQuery) &&
        user.signUpData.Email.toLowerCase().includes(
          emailQuery.toLowerCase()
        ) &&
        (isNaN(minAge) || user.selectedAge >= minAge) &&
        (isNaN(maxAge) || user.selectedAge <= maxAge) &&
        (!genderQuery ||
          user.selectedGender.toLowerCase() === genderQuery.toLowerCase()) &&
        user.category &&
        user.category.toLowerCase().includes(categoryQuery.toLowerCase())
      );
    } else {
      if (searchQuery == "") {
        return (
          user.subscription.subscriptionStatus
            .toLowerCase()
            .startsWith(planQuery.toLowerCase()) &&
          user.signUpData.Phone.toString().includes(phoneQuery) &&
          user.signUpData.Email.toLowerCase().includes(
            emailQuery.toLowerCase()
          ) &&
          (isNaN(minAge) || user.selectedAge >= minAge) &&
          (isNaN(maxAge) || user.selectedAge <= maxAge) &&
          (!genderQuery ||
            user.selectedGender.toLowerCase() === genderQuery.toLowerCase()) &&
          user.category &&
          user.category.toLowerCase().includes(categoryQuery.toLowerCase())
        );
      } else {
        return false;
      }
    }
  });

  const isAssignedItemPresent = (userData, item, category) => {
    return userData && userData[category] && userData[category].includes(item);
  };

  const updateUserWithAssignedItems = async (
    userId,
    exercisesToAdd,
    stretchingToAdd,
    meditationsToAdd,
    mealsToAdd
  ) => {
    try {
      const response = await fetch(`${serverURL}user/id=${userId}`);
      const userData = await response.json();

      if (userData) {
        const updatedExercises = userData.exercise
          ? [...userData.exercise, ...exercisesToAdd]
          : exercisesToAdd;
        const updatedStretching = userData.stretchingExercise
          ? [...userData.stretching, ...stretchingToAdd]
          : stretchingToAdd;

        const updatedMeditations = userData.meditation
          ? [...userData.meditation, ...meditationsToAdd]
          : meditationsToAdd;
        const updatedMeals = userData.meal
          ? [...userData.meal, ...mealsToAdd]
          : mealsToAdd;

        await fetch(`${serverURL}user/id=${userId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...userData,
            exercise: updatedExercises,
            stretching: updatedStretching,
            meditation: updatedMeditations,
            meal: updatedMeals,
          }),
        });

        console.log(`Assigned items added to user ID: ${userId}`);
      }
    } catch (error) {
      console.error(`Error adding assigned items to user ID: ${userId}`, error);
    }
  };

  const handleSave = async () => {
    filteredUsers.forEach(async (userId) => {
      const user = users[userId];
      if (user) {
        console.log(`User ID: ${userId}`);
        const exercisesToAdd = assignedExercises.filter(
          (exercise) => !isAssignedItemPresent(user, exercise, "exercise")
        );
        const stretchingToAdd = assignedStretchings.filter(
          (stretchingExercise) =>
            !isAssignedItemPresent(user, stretchingExercise, "stretching")
        );
        const meditationsToAdd = assignedMeditations.filter(
          (meditation) => !isAssignedItemPresent(user, meditation, "meditation")
        );
        const mealsToAdd = assignedMeals.filter(
          (meal) => !isAssignedItemPresent(user, meal, "meal")
        );

        if (
          exercisesToAdd.length > 0 ||
          stretchingToAdd.length > 0 ||
          meditationsToAdd.length > 0 ||
          mealsToAdd.length > 0
        ) {
          await updateUserWithAssignedItems(
            userId,
            exercisesToAdd,
            stretchingToAdd,
            meditationsToAdd,
            mealsToAdd
          );
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Items have been assigned successfully!",
            showConfirmButton: true,
          });
        }
      }
    });
  };

  const handleSortByName = () => {
    const newSortOrder = sortOrderName === "asc" ? "desc" : "asc";
    setSortOrderName(newSortOrder);
    setUsers(
      (prevUsers) =>
        prevUsers &&
        Object.values(prevUsers).sort((a, b) => {
          const nameA = a.signUpData.Name || a.signUpData.FirstName || "";
          const nameB = b.signUpData.Name || b.signUpData.FirstName || "";
          return newSortOrder === "asc"
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
        })
    );
  };

  const handleSortByAge = () => {
    const newSortOrder = sortOrderAge === "asc" ? "desc" : "asc";
    setSortOrderAge(newSortOrder);
    setUsers(
      (prevUsers) =>
        prevUsers &&
        Object.values(prevUsers).sort((a, b) =>
          newSortOrder === "asc"
            ? a.selectedAge - b.selectedAge
            : b.selectedAge - a.selectedAge
        )
    );
  };
  const handleSortByHeight = () => {
    const newSortOrder = sortOrderHeight === "asc" ? "desc" : "asc";
    setSortOrderHeight(newSortOrder);
    setUsers(
      (prevUsers) =>
        prevUsers &&
        Object.values(prevUsers).sort((a, b) =>
          newSortOrder === "asc"
            ? a.selectedHeight - b.selectedHeight
            : b.selectedHeight - a.selectedHeight
        )
    );
  };
  const handleSortByCreationTime = () => {
    const newSortOrder = sortOrderCreationTime === "asc" ? "desc" : "asc";
    setSortOrderCreationTime(newSortOrder);
    setUsers(
      (prevUsers) =>
        prevUsers &&
        Object.values(prevUsers).sort((a, b) => {
          const dateA = new Date(a.creationTime);
          const dateB = new Date(b.creationTime);
          return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
        })
    );
  };

  const handleSortByWeight = () => {
    const newSortOrder = sortOrderWeight === "asc" ? "desc" : "asc";
    setSortOrderWeight(newSortOrder);
    setUsers(
      (prevUsers) =>
        prevUsers &&
        Object.values(prevUsers).sort((a, b) =>
          newSortOrder === "asc"
            ? a.selectedWeight - b.selectedWeight
            : b.selectedWeight - a.selectedWeight
        )
    );
  };
  const handleSortByPlan = () => {
    const newSortOrder = sortOrderPlan === "asc" ? "desc" : "asc";
    setSortOrderPlan(newSortOrder);
    setUsers(
      (prevUsers) =>
        prevUsers &&
        Object.values(prevUsers).sort((a, b) => {
          const planA = a.subscription.subscriptionStatus === "active" ? 1 : 0;
          const planB = b.subscription.subscriptionStatus === "active" ? 1 : 0;
          return newSortOrder === "asc" ? planA - planB : planB - planA;
        })
    );
  };

  const formatDateTime = (dateTime) => {
    if (!dateTime) return "";
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString("en-US");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-row flex-wrap">
                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Search by name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Search by plan"
                      value={planQuery}
                      onChange={(e) => setPlanQuery(e.target.value)}
                    />
                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Search by phone number"
                      value={phoneQuery}
                      onChange={(e) => setPhoneQuery(e.target.value)}
                    />
                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Search by email"
                      value={emailQuery}
                      onChange={(e) => setEmailQuery(e.target.value)}
                    />
                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Min Age"
                      value={minAgeQuery}
                      onChange={(e) => setMinAgeQuery(e.target.value)}
                    />
                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Max Age"
                      value={maxAgeQuery}
                      onChange={(e) => setMaxAgeQuery(e.target.value)}
                    />
                    <select
                      className="col-md-2"
                      value={genderQuery}
                      onChange={(e) => setGenderQuery(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Others</option>
                    </select>

                    <input
                      className="col-md-2"
                      type="text"
                      placeholder="Body Type"
                      value={categoryQuery}
                      onChange={(e) => setCategoryQuery(e.target.value)}
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="p-2">
                      <label>Exercises:</label>
                      <br />
                      <button onClick={handleUnassignedClick}>
                        Add Exercise
                      </button>
                      <br />
                    </div>
                    <div className="p-2">
                      <label>Stretching:</label>
                      <br />
                      <button onClick={handleStretchingUnassignedClick}>
                        Add Stretching
                      </button>
                      <br />
                    </div>
                    <div className="p-2">
                      <label>Meditation:</label>
                      <br />
                      <button onClick={handleMeditationUnassignedClick}>
                        Add Meditation
                      </button>
                      <br />
                    </div>
                    <div className="p-2">
                      <label>Meals:</label>
                      <br />
                      <button onClick={handleMealUnassignedClick}>
                        Add Meal
                      </button>
                      <br />
                    </div>
                    <button onClick={handleSave} className="btn btn-info mt-4">
                      {" "}
                      Save
                    </button>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div className="p-2">
                      {showAddNew && (
                        <div>
                          {allBodyparts.map((bodypart) => (
                            <table className="exercise-table">
                              <thead>
                                <tr>
                                  <th>{bodypart.title}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {exercise.map((exercise) => {
                                  if (
                                    exercise &&
                                    exercise.bodypart.find(
                                      (obj) => obj._id === bodypart._id
                                    )
                                  ) {
                                    return (
                                      <tr key={exercise._id}>
                                        <td>{exercise.title}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="exercise"
                                            value={exercise._id}
                                            checked={isSelected(
                                              assignedExercises,
                                              exercise._id
                                            )}
                                            onChange={handleSelectChange}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </tbody>
                            </table>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="p-2">
                      {showAddNewStretchings && (
                        <div>
                          <table className="exercise-table">
                            <thead>
                              <tr>
                                <th>Stretchings</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {unassignedStretchings.map((part) => (
                                <tr key={part._id}>
                                  <td>{part.title}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="stretching"
                                      value={part._id}
                                      checked={isSelected(
                                        assignedStretchings,
                                        part._id
                                      )}
                                      onChange={handleSelectChange}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                    <div className="p-2">
                      {showAddNewMeditations && (
                        <div>
                          <table className="exercise-table">
                            <thead>
                              <tr>
                                <th>Meditations</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {unassignedMeditations.map((part) => (
                                <tr key={part._id}>
                                  <td>{part.title}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="meditation"
                                      value={part._id}
                                      checked={isSelected(
                                        assignedMeditations,
                                        part._id
                                      )}
                                      onChange={handleSelectChange}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                    <div className="p-2">
                      {showAddNewMeals && (
                        <div>
                          {mealTypes.map((mealtype) => (
                            <table
                              className="exercise-table"
                              key={mealtype._id}
                            >
                              <thead>
                                <tr>
                                  <th>{mealtype.type_title}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {unassignedMeals.map((meal) => {
                                  if (
                                    meal.type &&
                                    meal.type.find(
                                      (type) => type._id === mealtype._id
                                    )
                                  ) {
                                    return (
                                      <tr key={meal._id}>
                                        <td>{meal.title}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="meal"
                                            value={meal._id}
                                            checked={isSelected(
                                              assignedMeals,
                                              meal._id
                                            )}
                                            onChange={handleSelectChange}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return null;
                                })}
                              </tbody>
                            </table>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="col-md-6">
                    <button
                      class="btn btn-primary"
                      onClick={() => convertArrayToCSV(users, "Users Data")}
                    >
                      Download CSV file
                    </button>
                  </div>
                  <table className="table table-bordered">
                    <thead className="bs thead-dark">
                      <tr>
                        <th>#</th>
                        <th
                          onClick={handleSortByCreationTime}
                          style={{ cursor: "pointer" }}
                        >
                          Creation Time {sortOrderName === "asc" ? "▲" : "▼"}
                        </th>
                        <th
                          onClick={handleSortByName}
                          style={{ cursor: "pointer" }}
                        >
                          Name {sortOrderName === "asc" ? "▲" : "▼"}
                        </th>
                        <th>Email</th>
                        <th>Gender</th>
                        <th
                          onClick={handleSortByAge}
                          style={{ cursor: "pointer" }}
                        >
                          Age {sortOrderAge === "asc" ? "▲" : "▼"}
                        </th>
                        <th>Phone number</th>
                        <th>Body Type</th>
                        {/* <th>Meal</th> */}
                        <th
                          onClick={handleSortByPlan}
                          style={{ cursor: "pointer" }}
                        >
                          Plan opted {sortOrderPlan === "asc" ? "▲" : "▼"}
                        </th>
                        <th>Consultation</th>
                        <th
                          onClick={handleSortByHeight}
                          style={{ cursor: "pointer" }}
                        >
                          Height {sortOrderHeight === "asc" ? "▲" : "▼"}
                        </th>
                        <th
                          onClick={handleSortByWeight}
                          style={{ cursor: "pointer" }}
                        >
                          Weight {sortOrderWeight === "asc" ? "▲" : "▼"}
                        </th>
                        <th>Food</th>
                        <th>Health Issue</th>
                        <th>Habits</th>
                        <th>Level</th>
                        <th>Target</th>
                        <th>Allergy</th>
                        {/* <th>Exercise</th>
												<th>Stretching</th>
												<th>Meditation</th> */}
                        <th>Operations</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        filteredUsers.map((userId, index) => {
                          const user = users[userId];
                          // const date = new Date(user.metadata.creationTime);
                          // const exerciseTitles = user.exercise.map((exercise) => exercise.title).join(", ");
                          // const stretchingTitles = user.stretching.map((stretching) => stretching.title).join(", ");
                          // const meditationTitles = user.meditation.map((meditation) => meditation.title).join(", ");
                          // const mealTitles = user.meal.map((meal) => meal.title).join(", ");
                          const handleEdit = () => {
                            navigate(`/user/edit/id=${userId} `);
                          };

                          const handleDelete = () => {
                            navigate(`/user/delete/id=${userId}`);
                          };

                          return (
                            <tr key={userId}>
                              <td>{index + 1}</td>
                              <td>
                                {user.creationTime
                                  ? formatDateTime(user.creationTime)
                                  : " "}
                              </td>
                              <td>
                                {user.signUpData.Name
                                  ? user.signUpData.Name
                                  : user.signUpData.FirstName +
                                    " " +
                                    user.signUpData.LastName}
                              </td>
                              <td>{user.signUpData.Email}</td>
                              <td>{user.selectedGender}</td>
                              <td>{user.selectedAge}</td>
                              <td>
                                {user.signUpData.Mobile
                                  ? user.signUpData.Mobile
                                  : user.signUpData.Phone}
                              </td>
                              <td>{user.category}</td>
                              {/* <td>{mealTitles}</td> */}
                              <td>{user.subscription.subscriptionStatus}</td>
                              <td>
                                {user.consultation === "yes"
                                  ? user.timings && user.timings.length > 0
                                    ? user.timings
                                    : "unavailable"
                                  : "no"}
                              </td>
                              <td>{user.selectedHeight}</td>
                              <td>{user.selectedWeight}</td>
                              <td>{user.selectedFood}</td>
                              <td>{user.selectedHealthIssue}</td>
                              <td>{user.habits}</td>
                              <td>{user.selectedLevel}</td>
                              <td>{user.selectedTarget}</td>
                              <td>{user.allergy}</td>
                              {/* <td>{exerciseTitles}</td>
													<td>{stretchingTitles}</td>
													<td>{meditationTitles}</td> */}
                              <td>
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={handleEdit}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={handleDelete}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
