import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "./Style.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function AddMeal() {

  const token = getCookie('token')
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        if (response.status !== 200) {
          window.location.href = '/login';
        }
      } catch (error) {
        window.location.href = '/login';
      }
    }
    getUser()
  }, [token]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('free');
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    imageUrl: "",
    type: [],
    proteinContent: 0,
    fatContent: 0,
    carbs: 0,
    calories: 0,
    servings: 0,
    description: "",
    price: selectedPrice,
  });

  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
    setFormData({ ...formData, price: event.target.value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formD = new FormData();
    formD.append('image', selectedFile);
    //  console.log(formD);
    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setFormData((prevData) => ({
          ...prevData,
          imageUrl: response.data.imagePath,
        }));
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const selectedValues = [...formData.type];

      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        type: selectedValues,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    axios.get(`${serverURL}mealtypes`)
      .then(response => {
        setOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData)
      await axios.post(`${serverURL}meal`, formData, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire('Congrats', 'Meal added successfully!', 'success').then(result => {
        window.location.href = '/meal';
      });
    } catch (error) {
      console.error("Error adding meal:", error.response.data);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="meal-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="file"
            id="imageUrl"
            name="imageUrl"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL + imagePath} style={{ height: "200px" }} alt="Uploaded" />}
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Meal Type</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {options.map(option => (
                <tr key={option._id}>
                  <td>{option.type_title}</td>
                  <td>
                    <input
                      type="checkbox"
                      id={option._id}
                      name="type"
                      value={option._id}
                      checked={formData.type.includes(option._id)}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="carbs">Carbohydrates Content</label>
          <input type="text" id="carbContent" name="carbs" value={formData.carbs}  placeholder="Only Number accepted" onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="calories">Calories</label>
          <input type="text" id="fatContent" name="calories" placeholder="Only Number accepted" value={formData.calories} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="servings">Servings</label>
          <input type="text" id="servings" name="servings" placeholder="Only Number accepted" value={formData.servings} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="price">Price:</label>
          <div>
            <label>
              <input
                type="radio"
                value="free"
                checked={selectedPrice === 'free'}
                onChange={handlePriceChange}
              />
              Free
            </label>

            <label>
              <input
                type="radio"
                value="premium"
                checked={selectedPrice === 'premium'}
                onChange={handlePriceChange}
              />
              Premium
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="proteinContent">Protein content:</label>
          <input
            type="number"
            id="proteinContent"
            name="proteinContent"
            placeholder="Only Number accepted" 
            value={formData.proteinContent}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="fatContent">Fat content:</label>
          <input
            type="number"
            id="fatContent"
            name="fatContent"
            value={formData.fatContent}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-group"
          />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}