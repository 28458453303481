import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import './Style.css'
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function AddProduct() {

    const token = getCookie('token')
    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axios.get(`${serverURL}getuser`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                if (response.status !== 200) {
                    window.location.href = '/login';
                }
            } catch (error) {
                window.location.href = '/login';
            }
        }
        getUser()
    }, [token]);

    const [options, setOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImagePath] = useState('');
    const [videoPath, setVideoPath] = useState('');

    const [formData, setFormData] = useState({
        title: "",
        image: "",
        video: '',
        type:"",
        price: ""
    });
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleUpload = (e) => {
        e.preventDefault()
        const formD = new FormData();
        formD.append('image', selectedFile);

        axios
            .post(`${serverURL}upload`, formD)
            .then((response) => {
                setImagePath(response.data.imagePath);
                setFormData({
                    ...formData,
                    image: response.data.imagePath,
                });
                console.log('Image uploaded:', response.data.imagePath);
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };

    useEffect(() => {
        axios.get(`${serverURL}producttypes`)
          .then(response => {
            setOptions(response.data);
          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
      }, [])

    const handleVideoUpload = (e) => {
        e.preventDefault()
        const formD = new FormData();
        formD.append('video', selectedFile);

        axios
            .post(`${serverURL}uploadvideo`, formD)
            .then((response) => {
                setVideoPath(response.data.videoPath);
                setFormData({
                    ...formData,
                    video: response.data.videoPath,
                });
                console.log('Video uploaded:', response.data.videoPath);
            })
            .catch((error) => {
                console.error('Error uploading video:', error);
            });
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            const selectedValues = [...formData.type];

            if (checked) {
                selectedValues.push(value);
            } else {
                const index = selectedValues.indexOf(value);
                if (index > -1) {
                    selectedValues.splice(index, 1);
                }
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: selectedValues,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${serverURL}product`, formData, {
                headers: {
                    authorization: token,
                },
            });

            Swal.fire('Congrats', 'Product added successfully!', 'success').then(result => {
                window.location.href = '/product'
            });
        } catch (error) {
            console.error("Error adding Product:", error);
        }
    };

    return (
        <div>
            <br />
            <form onSubmit={handleSubmit} className="exercise-form">
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="imageUrl">Image:</label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={handleFileChange}
                        className="form-input"
                    />
                    <button onClick={handleUpload}>Upload Image</button>
                    {imagePath && <img src={serverURL + imagePath} style={{height: "200px"}} alt="Uploaded" />}
                </div>
                <div className="form-group">
                    <label htmlFor="videoUrl">Video:</label>
                    <input
                        type="file"
                        id="video"
                        name="video"
                        onChange={handleFileChange}
                        className="form-input"
                    />
                    <button onClick={handleVideoUpload}>Upload Video</button>
                    {videoPath && <video width="400" height="300" controls>
                        <source src={serverURL + videoPath} type="video/mp4" />
                    </video>}
                </div>
                <div className="form-group">
                    <label htmlFor="type">Type:</label>
                    <table className="exercise-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {options.map(option => (
                                <tr key={option._id}>
                                    <td>{option.title}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            id={option._id}
                                            name="type"
                                            value={option._id}
                                            checked={formData.type.includes(option._id)}
                                            onChange={handleChange}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="form-group">
                    <label htmlFor="title">Price:</label>
                    <input
                        type="text"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
}