import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";
import VideoPlayer from "./VideoPlayer";
export default function ShowExercise() {

	const token = getCookie('token')
	useEffect(() => {
		const getUser = async () => {
			try {
				const response = await axios.get(`${serverURL}getuser`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				if (response.status !== 200) {
					window.location.href = '/login';
				}
			} catch (error) {
				window.location.href = '/login';
			}
		}
		getUser()
	}, [token]);


	const navigate = useNavigate();

	const [products, setProducts] = useState([])
	const [searchQuery, setSearchQuery] = useState("");


	useEffect(() => {
		axios.get(`${serverURL}product`)
			.then(response => {
				console.log(response.data)
				setProducts(response.data);
			})
			.catch(error => {
			});
	}, []);

	const filteredExercises = products.filter((product) =>
		product.title.toLowerCase().includes(searchQuery.toLowerCase())
	);

	return (
		<>
			<div className="dashboard d-flex">
				<div>
					<Sidebar />
				</div>
				<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
					<div style={{ height: "100%" }}>
						<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

							<div className='row'>
									<div className="col-md-12">
										<input
											type="text"
											placeholder="Search by Title"
											value={searchQuery}
											onChange={(e) => setSearchQuery(e.target.value)}
										/>
									</div>
									<table className='table table-bordered'>
										<thead className='bs thead-dark'>
											<tr>
												<th>#</th>
												<th>Title</th>
												<th>Image</th>
												<th>Video</th>
												<th>Type</th>
												<th>Price</th>
												<th>Operations</th>
											</tr>
										</thead>
										<tbody>
											{filteredExercises.map((product, index) => {
												const handleEdit = () => {
													navigate(`/product/edit/id=${product._id}`);
												};

												const handleDelete = () => {
													navigate(`/product/delete/id=${product._id}`);
												};

												return (
													<tr key={product._id}>
														<td>{index + 1}</td>
														<td>{product.title}</td>
														<td>
															{product.image && (
																<img src={`${serverURL}${product.image}`} alt={product.title} style={{ width: "100px" }} />
															)}
														</td>
														<td>
															{product.video && (
																<VideoPlayer src={serverURL + product.video} />
															)}
														</td>
														<td>{product.type.title}</td>
														<td>{product.price}</td>
														<td>
															<button className="btn btn-sm btn-primary" onClick={handleEdit}>Edit</button>
															<button className="btn btn-sm btn-danger" onClick={handleDelete}>Delete</button>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
		</>
	);

}
