import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import getCookie from "./getCookie";
import './Style.css'
import {serverURL} from "../serverURL";

export default function EditProductType() {
    const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

    const { id } = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
    const [productTypes, setProductTypes] = useState({
        title:"",
        image: "",
    });


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };
      const handleUpload = (e) => {
        e.preventDefault();
        const formD = new FormData();
        formD.append('image', selectedFile);
    
        axios
            .post(`${serverURL}upload`, formD)
            .then((response) => {
              setImagePath(response.data.imagePath);
              setProductTypes({
                ...productTypes,
                image: response.data.imagePath,
              });
              console.log('Image uploaded:', response.data.imagePath);
            })
            .catch((error) => {
              console.error('Error uploading image:', error);
            });
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductTypes((prevDo) => ({
            ...prevDo,
            [name]: value,
        }));
    };

    useEffect(() => {
        async function fetchproductType() {
            try {
                const response = await axios.get(`${serverURL}producttypes/${id}`);
                setProductTypes(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchproductType();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${serverURL}producttypes/edit/${id}`, productTypes,{
                headers: {
                  authorization: token,
                },
              });
            Swal.fire('Congrats', 'Product Type updated successfully!', 'success').then(result => {
                window.location.href = '/producttype'
            })
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="edit-bodypart-form">
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input type="text" id="title" name="title" value={productTypes.title} readOnly className="form-input" />
                </div>
                <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
              type="file"
              id="image"
              name="image"

              onChange={handleFileChange}
              className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL+imagePath} style={{height: "200px"}} alt="Uploaded" />}
        </div>
                <div className="form-group">
                    <label htmlFor="imageUrl">Image URL:</label>
                    <input type="text" id="imageUrl" name="imageUrl" value={productTypes.image} onChange={handleChange} className="form-input" />
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
}