import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";
import { type } from "@testing-library/user-event/dist/type";

export default function EditUser() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();

  const [allBodyparts, setAllBodyparts] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [stretching, setStretching] = useState([]);
  const [meditation, setMeditation] = useState([]);
  const [assignedExercises, setAssignedExercises] = useState([]);
  const [unassignedExercises, setUnassignedExercises] = useState([]);
  const [showAssigned, setShowAssigned] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [assignedStretchings, setAssignedStretchings] = useState([]);
  const [unassignedStretchings, setUnassignedStretchings] = useState([]);
  const [showAssignedStretchings, setShowAssignedStretchings] = useState(false);
  const [showAddNewStretchings, setShowAddNewStretchings] = useState(false);
  const [assignedMeditations, setAssignedMeditations] = useState([]);
  const [unassignedMeditations, setUnassignedMeditations] = useState([]);
  const [showAssignedMeditations, setShowAssignedMeditations] = useState(false);
  const [showAddNewMeditations, setShowAddNewMeditations] = useState(false);
  const [mealTypes, setMealTypes] = useState([]);
  const [meals, setMeals] = useState([]);
  const [assignedMeals, setAssignedMeals] = useState([]);
  const [unassignedMeals, setUnassignedMeals] = useState([]);
  const [showAssignedMeals, setShowAssignedMeals] = useState(false);
  const [showAddNewMeals, setShowAddNewMeals] = useState(false);
  const [filteredUnassignedExercises, setFilteredUnassignedExercises] = useState([]);
  const [filteredAssignedExercises, setFilteredAssignedExercises] = useState([]);

  const [searchTerm, setSearchTerm] = useState();

  const [user, setUser] = useState({
    allergy: "",
    consultation: "",
    selectedAge: 0,
    selectedFood: "",
    selectedGender: "",
    selectedHealthIssue: [],
    selectedHeight: 0,
    selectedLevel: "",
    selectedTarget: [],
    selectedWeight: 0,
    signUpData: {
      Email: "",
      Mobile: 0,
      Name: "",
      Password: "",
      checked: true,
    },
    subscription: {
      subscriptionStatus: "",
    },
    myjournal: {},
    waterTracker: {},
    exercise: [],
    stretching: [],
    meditation: [],
    meal: [],
  });

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.get(`${serverURL}user/${id}`);
        let userData = response.data;

        if (userData.exercise) {
          /*setExercise(userData.exercise)*/
        } else {
          userData = { ...userData, exercise: [] };
        }
        if (userData.stretching) {
          /*setStretching(userData.stretching)*/
        } else {
          userData = { ...userData, stretching: [] };
        }
        if (userData.meditation) {
          /*setMeditation(userData.meditation)*/
        } else {
          userData = { ...userData, meditation: [] };
        }
        if (userData.meal) {
          /*setMeal(userData.meal)*/
        } else {
          userData = { ...userData, meal: [] };
        }

        setUser({
          ...userData,
          // exercise: userData.exercise.map((part) => part._id),
          // stretching: userData.stretching.map((part) => part?part._id:[]),
          // meditation: userData.meditation.map((part) => part._id),
          // meal: userData.meal.map((part) => part._id)
        });

        setAssignedExercises(userData.exercise);
        setFilteredAssignedExercises(userData.exercise)
        setAssignedStretchings(userData.stretching);
        setAssignedMeditations(userData.meditation);
        setAssignedMeals(userData.meal);
      } catch (error) {
        console.log(error);
      }
    }

    fetchUser();
  }, [id]);

  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const response = await axios.get(`${serverURL}exercise`);
        const allExercises = response.data;
        setExercise(allExercises);
        const unassignedExercises = allExercises.filter(
          (part) => !assignedExercises.includes(part._id.toString())
        );
        setUnassignedExercises(unassignedExercises);
        setFilteredUnassignedExercises(unassignedExercises);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
    };

    fetchExercise();
  }, [assignedExercises]);

  useEffect(() => {
    const fetchBodypart = async () => {
      try {
        const response = await axios.get(`${serverURL}bodypart`);
        setAllBodyparts(response.data);
      } catch (error) {
        console.error("Error fetching Bodyparts:", error);
      }
    };

    fetchBodypart();
  }, []);

  useEffect(() => {
    const fetchStretching = async () => {
      try {
        const response = await axios.get(`${serverURL}stretching`);
        const allStretchings = response.data;
        setStretching(allStretchings);
        const unassignedStretchings = allStretchings.filter(
          (part) => !assignedStretchings.includes(part._id.toString())
        );
        setUnassignedStretchings(unassignedStretchings);
      } catch (error) {
        console.error("Error fetching stretchings:", error);
      }
    };

    fetchStretching();
  }, [assignedStretchings]);

  useEffect(() => {
    const fetchMeditation = async () => {
      try {
        const response = await axios.get(`${serverURL}meditation`);
        const allMeditations = response.data;
        setMeditation(allMeditations);
        const unassignedMeditations = allMeditations.filter(
          (part) => !assignedMeditations.includes(part._id.toString())
        );
        setUnassignedMeditations(unassignedMeditations);
      } catch (error) {
        console.error("Error fetching meditations:", error);
      }
    };

    fetchMeditation();
  }, [assignedMeditations]);

  useEffect(() => {
    const fetchMealTypes = async () => {
      try {
        const response = await axios.get(`${serverURL}mealtypes`);
        setMealTypes(response.data);
      } catch (error) {
        console.error("Error fetching Bodyparts:", error);
      }
    };

    fetchMealTypes();
  }, []);

  useEffect(() => {
    const fetchMeal = async () => {
      try {
        const response = await axios.get(`${serverURL}meal`);
        const allMeals = response.data;
        setMeals(allMeals);
        const unassignedMeals = allMeals.filter(
          (part) => !assignedMeals.includes(part._id.toString())
        );
        setUnassignedMeals(unassignedMeals);
      } catch (error) {
        console.error("Error fetching Meals:", error);
      }
    };

    fetchMeal();
  }, [assignedMeals]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedUser = { ...user };

    // Update the nested property based on the input name
    if (name.includes(".")) {
      const [outerKey, innerKey] = name.split(".");
      updatedUser[outerKey][innerKey] = value;
    } else {
      updatedUser[name] = value;
    }

    setUser(updatedUser);
  };

  const handleSelectChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "exercise") {
      if (checked) {
        setAssignedExercises((prevExercises) => [...prevExercises, value]);
        setFilteredAssignedExercises((prevExercises) => [...prevExercises, value])
        console.log(assignedExercises, filteredAssignedExercises)
        setUnassignedExercises((prevExercises) =>
          prevExercises.filter((exercise) => exercise._id !== value)
        );
      } else {
        setAssignedExercises((prevExercises) =>
          prevExercises.filter((exerciseId) => exerciseId !== value)
        );
        setFilteredAssignedExercises((prevExercises) =>
          prevExercises.filter((exerciseId) => exerciseId !== value)
        );
        const unassignedPart = exercise.find((part) => part._id === value);
        if (unassignedPart) {
          setUnassignedExercises((prevExercises) => [
            ...prevExercises,
            unassignedPart,
          ]);
        }
        //  setUnassignedExercises((prevExercises) => [
        //    ...prevExercises,
        //    exercise.find((part) => part._id === value)
        //  ]);
      }
    }

    if (name === "stretching") {
      if (checked) {
        setAssignedStretchings((prevStretchings) => [
          ...prevStretchings,
          value,
        ]);
        setUnassignedStretchings((prevStretchings) =>
          prevStretchings.filter((stretching) => stretching._id !== value)
        );
      } else {
        setAssignedStretchings((prevStretchings) =>
          prevStretchings.filter((stretchingId) => stretchingId !== value)
        );
        setUnassignedStretchings((prevStretchings) => [
          ...prevStretchings,
          stretching.find((part) => part._id === value),
        ]);
      }
    }

    if (name === "meditation") {
      if (checked) {
        setAssignedMeditations((prevMeditations) => [
          ...prevMeditations,
          value,
        ]);
        setUnassignedMeditations((prevMeditations) =>
          prevMeditations.filter((meditation) => meditation._id !== value)
        );
      } else {
        setAssignedMeditations((prevMeditations) =>
          prevMeditations.filter((meditationId) => meditationId !== value)
        );
        setUnassignedMeditations((prevMeditations) => [
          ...prevMeditations,
          meditation.find((part) => part._id === value),
        ]);
      }
    }

    if (name === "meal") {
      if (checked) {
        setAssignedMeals((prevMeals) => [...prevMeals, value]);
        setUnassignedMeals((prevMeals) =>
          prevMeals.filter((meal) => meal._id !== value)
        );
      } else {
        setAssignedMeals((prevMeals) =>
          prevMeals.filter((mealId) => mealId !== value)
        );
        setUnassignedMeals((prevMeals) => [
          ...prevMeals,
          meals.find((part) => part._id === value),
        ]);
      }
    }

    if (name === "phoneNumber") {
      const cleanedValue = value.replace(/\D/g, "");

      const truncatedValue = cleanedValue.slice(0, 10);

      this.setUser((prevUser) => ({
        user: {
          ...prevUser,
          phoneNumber: truncatedValue,
        },
      }));
    }

    setUser((prevUser) => ({
      ...prevUser,
      [name]: checked
        ? [...prevUser[name], value]
        : prevUser[name].filter((item) => item !== value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = { ...user };
    const updatedExercise = updatedUser.exercise.filter(
      (partId) => partId !== "add_new"
    );
    const updatedStretching = updatedUser.stretching.filter(
      (partId) => partId !== "add_new"
    );
    const updatedMeditation = updatedUser.meditation.filter(
      (partId) => partId !== "add_new"
    );
    const updatedMeal = updatedUser.meal.filter(
      (partId) => partId !== "add_new"
    );
    updatedUser.meal = updatedMeal;
    updatedUser.stretching = updatedStretching;
    updatedUser.exercise = updatedExercise;
    updatedUser.meditation = updatedMeditation;
    setUser(updatedUser);
    try {
      //   console.log(updatedUser)
      await axios.put(`${serverURL}user/${id}`, updatedUser, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire("Congrats", "User updated successfully!", "success").then(
        (result) => {
          window.location.href = "/";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const isSelected = (array, id) => array.includes(id.toString());

  const handleAssignedClick = async (e) => {
    e.preventDefault();
    setShowAddNew(false);
    setShowAssigned(true);
  };

  const handleUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAssigned(false);
    setShowAddNew(true);
  };

  const handleStretchingAssignedClick = async (e) => {
    e.preventDefault();
    setShowAddNewStretchings(false);
    setShowAssignedStretchings(true);
  };

  const handleStretchingUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAssignedStretchings(false);
    setShowAddNewStretchings(true);
  };

  const handleMeditationAssignedClick = async (e) => {
    e.preventDefault();
    setShowAddNewMeditations(false);
    setShowAssignedMeditations(true);
  };

  const handleMeditationUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAssignedMeditations(false);
    setShowAddNewMeditations(true);
  };

  const handleMealAssignedClick = async (e) => {
    e.preventDefault();
    setShowAddNewMeals(false);
    setShowAssignedMeals(true);
  };

  const handleMealUnassignedClick = async (e) => {
    e.preventDefault();
    setShowAssignedMeals(false);
    setShowAddNewMeals(true);
  };


  const handleSearchChange = (event, section) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (!searchTerm || searchTerm.trim() === "") {
      if (section === "assigned") {
        setFilteredAssignedExercises(assignedExercises);
      } else if (section === "unassigned") {
        setFilteredUnassignedExercises(unassignedExercises);
      }
    } else {
      updateFilteredExercises(searchTerm, section);
    }
  };
  const updateFilteredExercises = (searchTerm, section) => {
    if (!searchTerm || searchTerm.trim() === "") {
      if (section === "assigned") {
        setFilteredAssignedExercises(assignedExercises);
      } else if (section === "unassigned") {
        setFilteredUnassignedExercises(unassignedExercises);
      }
    } else {
      const filteredExercises = filterExercisesBySearchTerm(searchTerm, section);
  
      if (section === "assigned") {
        setFilteredAssignedExercises(filteredExercises);
      } else if (section === "unassigned") {
        setFilteredUnassignedExercises(filteredExercises);
      }
    }
  };
    
  const filterExercisesBySearchTerm = (searchTerm, section) => {
    let exercises;
    if (section === "assigned") {
      exercises = assignedExercises;
    } else if (section === "unassigned") {
      exercises = unassignedExercises;
    } else {
      return [];
    }
  
    if (!searchTerm || searchTerm.trim() === "") {
      return exercises;
    } else {
      if(section=="unassigned"){
        return exercises.filter((exercise) =>
          exercise.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      else if(section=="assigned"){
        return exercises.filter((exerciseId) => {
          console.log(exercises)
          const exer = exercise.find((ex) => ex._id === exerciseId);
          return exer && exer.title.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }
    }
  };
  

  return (
    <div>
      <form>
        <br />
        <div>
          <label>Name:</label>
          <input
            type="text"
            name={
              user.signUpData.Name ? "signUpData.Name" : "signUpData.FirstName"
            }
            value={
              user.signUpData.Name
                ? user.signUpData.Name
                : user.signUpData.FirstName + " " + user.signUpData.LastName
            }
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="signUpData.Email"
            value={user.signUpData.Email}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Gender:</label>
          <input
            type="text"
            name="selectedGender"
            value={user.selectedGender}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Age:</label>
          <input
            type="number"
            name="selectedAge"
            value={user.selectedAge}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Plan Opted:</label>
          <input
            type="tel"
            name="subscription.subscriptionStatus"
            value={user.subscription.subscriptionStatus}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Phone number:</label>
          <input
            type="number"
            name="signUpData.Mobile"
            value={
              user.signUpData.Mobile
                ? user.signUpData.Mobile
                : user.signUpData.Phone
            }
            onChange={handleChange}
            maxLength={10}
            minLength={10}
          />
        </div>

        <br />
        <div className="d-flex flex-row justify-content-between">
          <div className="p-2">
            <label>Exercises:</label>
            <br />
            <button onClick={handleAssignedClick}>Assigned</button>
            <br />
            <button onClick={handleUnassignedClick}>Add New</button>
          </div>
          <br />
          <div className="p-2">
            <label>Stretchings:</label>
            <br />
            <button onClick={handleStretchingAssignedClick}>Assigned</button>
            <br />
            <button onClick={handleStretchingUnassignedClick}>Add New</button>
          </div>
          <br />
          <div className="p-2">
            <label>Meditations:</label>
            <br />
            <button onClick={handleMeditationAssignedClick}>Assigned</button>
            <br />
            <button onClick={handleMeditationUnassignedClick}>Add New</button>
          </div>
          <br />
          <div className="p-2">
            <label>Meals:</label>
            <br />
            <button onClick={handleMealAssignedClick}>Assigned</button>
            <br />
            <button onClick={handleMealUnassignedClick}>Add New</button>
          </div>
          <br />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="p-2">
            {showAssigned && (
              <div>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e, "assigned")}
                />
                {allBodyparts.map((bodypart) => (
                  <table className="exercise-table" key={bodypart._id}>
                    <thead>
                      <tr>
                        <th>{bodypart.title}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
              {filteredAssignedExercises.map((exerciseId) => {
              const exer = exercise.find((ex) => ex._id === exerciseId);
              
              if (exer && exer.bodypart.find(obj => obj._id === bodypart._id)) {
                return (
                  <tr key={exer._id}>
                    <td>{exer.title}</td>
                    <td>
                      <input
                        type="checkbox"
                        name="exercise"
                        value={exer._id}
                        checked={isSelected(assignedExercises, exer._id)}
                        onChange={handleSelectChange}
                      />
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          
              </tbody>
                  </table>
                ))}
              </div>
            )}
            {showAddNew && (
              <div>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e, "unassigned")}
                />
                {allBodyparts.map((bodypart) => (
                  <table className="exercise-table">
                    <thead>
                      <tr>
                        <th>{bodypart.title}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUnassignedExercises.map((exercise) => {
                        if (
                          exercise &&
                          exercise.bodypart.find(
                            (obj) => obj._id === bodypart._id
                          )
                        ) {
                          return (
                            <tr key={exercise._id}>
                              <td>{exercise.title}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="exercise"
                                  value={exercise._id}
                                  checked={isSelected(
                                    assignedExercises,
                                    exercise._id
                                  )}
                                  onChange={handleSelectChange}
                                />
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </table>
                ))}
              </div>
            )}
          </div>
          <div className="p-2">
            {showAssignedStretchings && (
              <div>
                <table className="exercise-table">
                  <thead>
                    <tr>
                      <th>Stretchings</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedStretchings.map((partId) => {
                      const part = stretching.find(
                        (part) => part._id === partId
                      );
                      if (part) {
                        return (
                          <tr key={part._id}>
                            <td>{part.title}</td>
                            <td>
                              <input
                                type="checkbox"
                                name="stretching"
                                value={part._id}
                                checked={isSelected(
                                  assignedStretchings,
                                  part._id
                                )}
                                onChange={handleSelectChange}
                              />
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {showAddNewStretchings && (
              <div>
                <table className="exercise-table">
                  <thead>
                    <tr>
                      <th>Stretchings</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {unassignedStretchings.map((part) => (
                      <tr key={part._id}>
                        <td>{part.title}</td>
                        <td>
                          <input
                            type="checkbox"
                            name="stretching"
                            value={part._id}
                            checked={isSelected(assignedStretchings, part._id)}
                            onChange={handleSelectChange}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <br />
          <div className="p-2">
            {showAssignedMeditations && (
              <div>
                <table className="exercise-table">
                  <thead>
                    <tr>
                      <th>Meditations</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedMeditations.map((partId) => {
                      const part = meditation.find(
                        (part) => part._id === partId
                      );
                      if (part) {
                        return (
                          <tr key={part._id}>
                            <td>{part.title}</td>
                            <td>
                              <input
                                type="checkbox"
                                name="meditation"
                                value={part._id}
                                checked={isSelected(
                                  assignedMeditations,
                                  part._id
                                )}
                                onChange={handleSelectChange}
                              />
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {showAddNewMeditations && (
              <div>
                <table className="exercise-table">
                  <thead>
                    <tr>
                      <th>Meditations</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {unassignedMeditations.map((part) => (
                      <tr key={part._id}>
                        <td>{part.title}</td>
                        <td>
                          <input
                            type="checkbox"
                            name="meditation"
                            value={part._id}
                            checked={isSelected(assignedMeditations, part._id)}
                            onChange={handleSelectChange}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <br />
          <br />
          <div className="p-2">
            {showAssignedMeals && (
              <div>
                {mealTypes.map((mealtype) => (
                  <table className="exercise-table" key={mealtype._id}>
                    <thead>
                      <tr>
                        <th>{mealtype.type_title}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignedMeals.map((mealId) => { 
                        const foundMeal = meals.find((meal) => meal._id === mealId);                                              
                        if (foundMeal && foundMeal.type && foundMeal.type.find((type)=> type._id === mealtype._id)) {
                          return (
                            <tr key={foundMeal._id}>
                              <td>{foundMeal.title}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="meal"
                                  value={foundMeal._id}
                                  checked={isSelected(assignedMeals, foundMeal._id)}
                                  onChange={handleSelectChange}
                                />
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>
                ))}
              </div>
            )}


            {showAddNewMeals && (
              <div>
                {mealTypes.map((mealtype) => (
                  <table className="exercise-table" key={mealtype._id}>
                    <thead>
                      <tr>
                        <th>{mealtype.type_title}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {unassignedMeals.map((meal) => {
                        if (meal.type && meal.type.find((type) => type._id === mealtype._id)) {
                          return (
                            <tr key={meal._id}>
                              <td>{meal.title}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="meal"
                                  value={meal._id}
                                  checked={isSelected(assignedMeals, meal._id)}
                                  onChange={handleSelectChange}
                                />
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>
                ))}
              </div>
            )}
          </div>
          <div className="p-2"></div>
        </div>
        <br />

        <button type="submit" onClick={handleSubmit}>
          Save Changes
        </button>
      </form>
    </div>
  );
}
