import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverURL";

const AddCoupon = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: "",
        discountText: "",
        discountDescription: "",
        code: "",
        discountLimit:0,
        discountLimitMCV:0,
        minimumCartValue:0
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}coupons/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const coupon = await response.json();
                Swal.fire(
                    "Success",
                    "Coupon added successfully!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/coupons");
                    }
                });
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Add a Coupon</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mt-6">
                        <label htmlFor="id" className="block mb-2">
                            ID:
                        </label>
                        <input
                            type="text"
                            id="id"
                            name="id"
                            value={formData.id}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="discountText" className="block mb-2">
                            Discount Text:
                        </label>
                        <input
                            type="text"
                            id="discountText"
                            name="discountText"
                            value={formData.discountText}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="discountDescription" className="block mb-2">
                            Discount Description:
                        </label>
                        <input
                            type="text"
                            id="discountDescription"
                            name="discountDescription"
                            value={formData.discountDescription}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="code" className="block mb-2">
                            Code:
                        </label>
                        <input
                            type="text"
                            id="code"
                            name="code"
                            value={formData.code}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="code" className="block mb-2">
                        discountLimit:
                        </label>
                        <input
                            type="Number"
                            id="discountLimit"
                            name="discountLimit"
                            value={formData.discountLimit}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="code" className="block mb-2">
                        discountLimitMCV:
                        </label>
                        <input
                            type="Number"
                            id="discountLimitMCV"
                            name="discountLimitMCV"
                            value={formData.discountLimitMCV}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="code" className="block mb-2">
                        minimumCartValue:
                        </label>
                        <input
                            type="Number"
                            id="minimumCartValue"
                            name="minimumCartValue"
                            value={formData.minimumCartValue}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="flex justify-center mt-6">
                        <button
                            type="submit"
                            className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCoupon;
