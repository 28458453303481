import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddUser() {

  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const [selectedExercises, setSelectedExercises] = useState([]);
  const [selectedStretchings, setSelectedStretchings] = useState([]);
  const [selectedMeditations, setSelectedMeditations] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);


  const [exercise, setExercise] = useState([])
  const [stretching, setStretching] = useState([])
  const [meditation, setMeditation] = useState([])
  const [meal,setMeal]=useState([])

  const getCurrentDateTime = () => {
    const currentDateTime = new Date();
    return currentDateTime.toISOString();
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    joined: getCurrentDateTime(),
    gender: "",
    age: "",
    planOpted: "",
    exercise: [],
    stretching: [],
    meditation: [],
    meal:[]
  });

  useEffect(() => {
    const fetchExercise = async () => {
      try {
        const response = await axios.get(`${serverURL}exercise`);
        setExercise(response.data);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
    };

    fetchExercise();
  }, []);


  useEffect(() => {
    const fetchStretching = async () => {
      try {
        const response = await axios.get(`${serverURL}stretching`);
        setStretching(response.data);
      } catch (error) {
        console.error("Error fetching stretchings:", error);
      }
    };

    fetchStretching();
  }, []);

  useEffect(() => {
    const fetchMeal = async () => {
      try {
        const response = await axios.get(`${serverURL}meal`);
        setMeal(response.data);
      } catch (error) {
        console.error("Error fetching Meals:", error);
      }
    };

    fetchMeal();
  }, []);


  useEffect(() => {
    const fetchMeditation = async () => {
      try {
        const response = await axios.get(`${serverURL}meditation`);
        setMeditation(response.data);
      } catch (error) {
        console.error("Error fetching meditations:", error);
      }
    };

    fetchMeditation();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange1 = (e) => {
    const { name, options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    switch (name) {
      case "exercise":
        setSelectedExercises(selectedOptions);
        break;
      case "stretching":
        setSelectedStretchings(selectedOptions);
        break;
      case "meditation":
        setSelectedMeditations(selectedOptions);
        break;
      case "meal":
        setSelectedMeals(selectedOptions);
        break;  
      default:
        break;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${serverURL}user`, formData);
      Swal.fire('Congrats', 'User added successfully!', 'success').then(result => {
        window.location.href = '/'
      })
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  return (
    <div>
      <h1>Add User</h1>
      <form onSubmit={handleSubmit}>
        <div>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        </div>
        <br />
        <div>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        </div>
        <br/>
        <div>
        <label>
          Joined:
          <input
            type="text"
            name="joined"
            readonly
            value={formData.joined}
            onChange={handleChange}
          />
        </label>
        </div>
        <br/>
        <div>
        <label>
          Gender:
          <input
            type="text"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          />
        </label>
        </div>
        <br/>
        <div>
        <label>
          Age:
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
          />
        </label>
        </div>
        <br/>
        <div>
        <label>
          Plan Opted:
          <input
            type="text"
            name="planOpted"
            value={formData.planOpted}
            onChange={handleChange}
          />
        </label>
        </div>
        <br/>
        <div>
        <label>Exercises:</label>
        <br />
        <select
          name="exercise"
          multiple
          value={selectedExercises}
          onChange={handleChange1}
          size={2}
        >
          {exercise.map((part) => (
            <option key={part._id} value={part._id}>
              {part.title}
            </option>
          ))}
        </select>
        </div>
        <br/>
        <div>
        <label>Stretchings:</label>
        <br />
        <select
          name="stretching"
          multiple
          value={selectedStretchings}
          onChange={handleChange1}
          size={2}
        >
          {stretching.map((part) => (
            <option key={part._id} value={part._id}>
              {part.title}
            </option>
          ))}
        </select>
        </div>
        <br/>
        <div>
        <label>Meditations:</label>
        <br />
        <select
          name="meditation"
          multiple
          value={selectedMeditations}
          onChange={handleChange1}
          size={2}
        >
          {meditation.map((part) => (
            <option key={part._id} value={part._id}>
              {part.title}
            </option>
          ))}
        </select>
        </div>
        <div>
        <label>Meals:</label>
        <br />
        <select
          name="meal"
          multiple
          value={selectedMeals}
          onChange={handleChange1}
          size={2}
        >
          {meal.map((part) => (
            <option key={part._id} value={part._id}>
              {part.title}
            </option>
          ))}
        </select>
        </div>
        <br/>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
