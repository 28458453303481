import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "./Style.css";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddDo() {

  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [formData, setFormData] = useState({
    title: "",
    type: { do: false, dont: false },
    imageUrl: "",
    description: ""
  });
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = () => {
    const formD = new FormData();
    formD.append('image', selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setFormData({
          ...formData,
          imageUrl: response.data.imagePath,
        });
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckboxChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      type: event.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${serverURL}do`, formData,{
        headers: {
          authorization: token,
        },
      });
      Swal.fire('Congrats', 'Dos and donts added successfully!', 'success').then(result => {
        window.location.href = '/do';
      });
    } catch (error) {
      console.error("Error adding Do:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="habit-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="do">Do</label>
                </td>
                <td>
                  <input
                    type="radio"
                    id="do"
                    name="type"
                    value="do"
                    checked={formData.type === "do"}
                    onChange={handleCheckboxChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="dont">Don't</label>
                </td>
                <td>
                  <input
                    type="radio"
                    id="dont"
                    name="type"
                    value="dont"
                    checked={formData.type === "dont"}
                    onChange={handleCheckboxChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="file"
            id="imageUrl"
            name="imageUrl"

            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL+imagePath} style={{height: "200px"}} alt="Uploaded" />}
        </div>
        <div className="form-group">
          <label htmlFor="type">Description:</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-group"
          />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}