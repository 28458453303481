import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function Editproduct() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [product, setProduct] = useState({
    title: "",
    type: "",
    image: "",
    imageUrl: "",
    video: "",
    videoUrl: "",
    price: "",
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await axios.get(`${serverURL}product/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchProduct();
  }, [id]);

  const handleImageFileChange = (event) => {
    setSelectedImageFile(event.target.files[0]);
  };

  const handleVideoFileChange = (event) => {
    setSelectedVideoFile(event.target.files[0]);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedImageFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImageUrl(response.data.imagePath);
        setProduct({
          ...product,
          image: response.data.imagePath,
          imageUrl: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleVideoUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("video", selectedVideoFile);

    axios
      .post(`${serverURL}uploadvideo`, formD)
      .then((response) => {
        setVideoUrl(response.data.videoPath);
        setProduct({
          ...product,
          video: response.data.videoPath,
          videoUrl: response.data.videoPath,
        });
        console.log("Video uploaded:", response.data.videoPath);
      })
      .catch((error) => {
        console.error("Error uploading video:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${serverURL}producttypes`)
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const isTypeChecked = (optionId) => {
    return optionId === product.type._id;
  };

  const handleTypeChange = (e) => {
    const optionId = e.target.value;
    const isChecked = e.target.checked;

    setProduct((prevProduct) => ({
      ...prevProduct,
      type: isChecked ? { _id: optionId } : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${serverURL}product/${id}`, product, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire("Congrats", "Product updated successfully!", "success").then(
        (result) => {
          window.location.href = "/product";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="edit-bodypart-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={product.title}
            readOnly
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageFileChange}
            className="form-input"
          />
          <button onClick={handleImageUpload}>Upload Image</button>
          {imageUrl && <img src={imageUrl} alt="Uploaded" />}
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={product.image}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="video">Video:</label>
          <input
            type="file"
            id="video"
            name="video"
            onChange={handleVideoFileChange}
            className="form-input"
          />
          <button onClick={handleVideoUpload}>Upload Video</button>
          {videoUrl && (
            <video width="400" height="300" controls>
              <source src={videoUrl} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="videoUrl">Video URL:</label>
          <input
            type="text"
            id="videoUrl"
            name="videoUrl"
            value={product.video}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Product Type</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {options.map((option) => (
                <tr key={option._id}>
                  <td>{option.title}</td>
                  <td>
                    <input
                      type="checkbox"
                      id={option._id}
                      name="type"
                      value={option._id}
                      checked={isTypeChecked(option._id)}
                      onChange={handleTypeChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="price">Price:</label>
          <input
            type="text"
            id="price"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}
