import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import './Style.css'
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddOneStretching() {
  
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);
  const [bodyParts, setBodyParts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [videoPath, setVideoPath] = useState('');

  const [formData, setFormData] = useState({
    title: "",
    image: "",
    video: '',
    bodypart: [],
    description:"",
    reps:"",
    sets:"",
    rest:"",
    level:""
  });
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault()
    const formD = new FormData();
    formD.append('image', selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setFormData({
          ...formData,
          image: response.data.imagePath,
        });
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };
  const handleVideoUpload = (e) => {
    e.preventDefault()
    const formD = new FormData();
    formD.append('video', selectedFile);

    axios
      .post(`${serverURL}uploadvideo`, formD)
      .then((response) => {
        setVideoPath(response.data.videoPath);
        setFormData({
          ...formData,
          video: response.data.videoPath,
        });
        console.log('Video uploaded:', response.data.videoPath);
      })
      .catch((error) => {
        console.error('Error uploading video:', error);
      });
  };

  useEffect(() => {
    const fetchBodyParts = async () => {
      try {
        const response = await axios.get(`${serverURL}bodypart`);
        setBodyParts(response.data);
      } catch (error) {
        console.error("Error fetching body parts:", error);
      }
    };

    fetchBodyParts();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      const selectedValues = [...formData.bodypart];
      
      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {  
      await axios.post(`${serverURL}onestretching`, formData, {
        headers: {
            authorization: token,
        },
    });
  
      Swal.fire('Congrats', 'oneStretching added successfully!', 'success').then(result => {
        window.location.href = '/onestretching'
      });
    } catch (error) {
      console.error("Error adding oneStretching:", error);
    }
  };

  return (
    <div>
      <br />
      <form onSubmit={handleSubmit} className="exercise-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <div>{imagePath && <img src={serverURL+imagePath} style={{height: "200px"}} alt="Uploaded" />}</div>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>          
        </div>
        <div className="form-group">
          <label htmlFor="videoUrl">Video:</label>
          <div>{videoPath && <video height="200" controls>
        <source src={serverURL + videoPath} type="video/mp4" />
      </video>}</div>
          <input
            type="file"
            id="video"
            name="video"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleVideoUpload}>Upload Video</button>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            type="textarea"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-group"
          />
        </div>
        <div className="form-group">
          <label htmlFor="reps">Reps:</label>
          <input
            type="text"
            id="reps"
            name="reps"
            placeholder="Only enter numbers"
            value={formData.reps}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="sets">Sets:</label>
          <input
            type="text"
            id="sets"
            name="sets"
            placeholder="Only enter numbers"
            value={formData.sets}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="rest">Rest:</label>
          <input
            type="text"
            id="rest"
            name="rest"
            placeholder="Only enter numbers"
            value={formData.rest}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="level">Level:</label>
          <input
            type="text"
            id="level"
            name="level"
            placeholder="Only enter numbers"
            value={formData.level}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>Body Parts:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Bodypart</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {bodyParts.map((part) => (
                <tr key={part._id}>
                  <td>{part.title}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="bodypart"
                      value={part._id}
                      checked={formData.bodypart.includes(part._id)}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}