import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "./Style.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function EditBodypart() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [bodypart, setBodypart] = useState({
    title: "",
    image: "",
  });

  useEffect(() => {
    async function fetchBodypart() {
      try {
        const response = await axios.get(`${serverURL}bodypart/${id}`);
        setBodypart(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchBodypart();
  }, [id]);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setBodypart({
          ...bodypart,
          image: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBodypart((prevBodypart) => ({
      ...prevBodypart,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(bodypart)
      await axios.put(`${serverURL}bodypart/${id}`, bodypart, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire("Congrats", "Bodypart updated successfully!", "success").then(
        (result) => {
          window.location.href = "/bodypart";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="edit-bodypart-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={bodypart.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && (
            <img
              src={serverURL + imagePath}
              style={{ height: "200px" }}
              alt="Uploaded"
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="text"
            id="imageUrl"
            name="image"
            value={bodypart.image}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="hasExercise">Has Exercise:</label>
          <input
            type="checkbox"
            id="imageUrl"
            name="hasExercise"
            className="m-2"
            checked={bodypart.hasExercise}
            value={bodypart.hasExercise}
            onChange={()=>setBodypart({...bodypart, hasExercise: !bodypart.hasExercise})}
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}
