import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverURL";
import axios from "axios";

const AddYoga = () => {
    const navigate = useNavigate();
    const [selectedFile1, setSelectedFile1] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath1, setImagePath1] = useState('');
    const [videoPath, setVideoPath] = useState('');
    const [formData, setFormData] = useState({
        title: "",
        image: "",
        video: '',
        content:"",
        reps:"",
        sets:"",
        rest:"",
        level:"",
        price:""
    });
    const handleFileChange1 = (event) => {
        setSelectedFile1(event.target.files[0]);
    };
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };

      const handleVideoUpload = (e) => {
        e.preventDefault()
        const formD = new FormData();
        formD.append('video', selectedFile);
        axios
          .post(`${serverURL}uploadvideo`, formD)
          .then((response) => {
            setVideoPath(response.data.videoPath);
            setFormData({
              ...formData,
              video: response.data.videoPath,
            });
            console.log('Video uploaded:', response.data.videoPath);
          })
          .catch((error) => {
            console.error('Error uploading video:', error);
          });
      };
      
    const handleUpload1 = async (e) => {
        e.preventDefault();
        const formD = new FormData();
        formD.append('image', selectedFile1);
      console.log(formData)
        try {
          const response = await fetch(`${serverURL}upload`, {
            method: 'POST',
            body: formD,
          });
      
          if (response.ok) {
            const data = await response.json();
            setImagePath1(data.imagePath);
            setFormData({
              ...formData,
              image1: data.imagePath,
            });
            console.log('Image uploaded:', data.imagePath);
          } else {
            throw new Error('Error uploading image');
          }
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      };
      

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleChange1 = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
      
        try {  
          await axios.post(`${serverURL}yoga`, formData, {
            // headers: {
            //     authorization: token,
            // },
        });
      
          Swal.fire('Congrats', 'Yoga added successfully!', 'success').then(result => {
            window.location.href = '/yoga'
          });
        } catch (error) {
          console.error("Error adding Yoga:", error);
        }
      };
    

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Create an Yoga</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label htmlFor="title" className="block text-gray-700 text-sm font-medium mb-2">
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="image1" className="block mb-2">
                            Image :
                        </label>
                        <input
                            type="file"
                            id="image1"
                            name="image1"
                            onChange={handleFileChange1}
                            className="border rounded p-2 mb-2"
                        />
                        <button
                            onClick={handleUpload1}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Upload Image
                        </button>
                        {imagePath1 && (
                            <img
                                src={`${serverURL}`+imagePath1}
                                alt="Uploaded"
                                style={{ height: "200px" }}
                                className="mt-2"
                            />
                        )}
                    </div>

        <div className="form-group">
          <label htmlFor="videoUrl">Video:</label>
          <div>{videoPath && <video height="200" controls>
        <source src={serverURL + videoPath} type="video/mp4" />
         </video>}</div>
          <input
            type="file"
            id="video"
            name="video"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleVideoUpload}>Upload Video</button>
        </div>
                   
                    <div className="mb-6">
                        <label htmlFor="content" className="block text-gray-700 text-sm font-medium mb-2">
                            Content
                        </label>
                        <textarea
                            id="content"
                            name="content"
                            value={formData.content}
                            onChange={handleChange}
                            required
                            rows="5"
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="content" className="block text-gray-700 text-sm font-medium mb-2">
                            Price
                        </label>
                        <textarea
                            id="price"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                            rows="5"
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="form-group">
          <label htmlFor="reps">Reps:</label>
          <input
            type="text"
            id="reps"
            name="reps"
            placeholder="Only enter numbers"
            value={formData.reps}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="sets">Sets:</label>
          <input
            type="text"
            id="sets"
            name="sets"
            placeholder="Only enter numbers"
            value={formData.sets}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="rest">Rest:</label>
          <input
            type="text"
            id="rest"
            name="rest"
            placeholder="Only enter numbers"
            value={formData.rest}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="level">Level:</label>
          <input
            type="text"
            id="level"
            name="level"
            placeholder="Only enter numbers"
            value={formData.level}
            onChange={handleChange}
            className="form-input"
          />
          </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddYoga;
