import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "./Style.css";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddHabitType() {

    const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImagePath] = useState('');
    const [formData, setFormData] = useState({
        type_title: "",
        product_image: "",
    });
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleUpload = () => {
        const formD = new FormData();
        formD.append('image', selectedFile);

        axios
            .post(`${serverURL}upload`, formD)
            .then((response) => {
                setImagePath(response.data.imagePath);
                setFormData((prevData) => ({
                    ...prevData,
                    product_image: response.data.imagePath,
                  }));
                //   console.log(formData)
                console.log('Image uploaded:', response.data.imagePath);
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };

    const handleChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${serverURL}habittypes`, formData, {
                headers: {
                    authorization: token,
                },
            });
            Swal.fire('Congrats', 'HabitTypes added successfully!', 'success').then(result => {
                window.location.href = '/habittype';
            });
        } catch (error) {
            console.error("Error adding Habit Type:", error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="habit-form">
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        name="type_title"
                        value={formData.type_title}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="imageUrl">Image URL:</label>
                    <input
                        type="file"
                        id="imageUrl"
                        name="imageUrl"
                        onChange={handleFileChange}
                        className="form-input"
                    />
                    <button onClick={handleUpload}>Upload Image</button>
                    {imagePath && <img src={serverURL+imagePath} style={{height: "200px"}} alt="Uploaded" />}
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
}