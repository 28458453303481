import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "./Style.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function AddBodypart() {
  const token = getCookie('token');

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = '/login';
        }
      } catch (error) {
        window.location.href = '/login';
      }
    }
    getUser();
  }, [token]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [formData, setFormData] = useState({
    title: "",
    image: "",
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formD = new FormData();
    formD.append('image', selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setFormData({
          ...formData,
          image: response.data.imagePath,
        });
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${serverURL}bodypart`, formData, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire('Congrats', 'Bodypart added successfully!', 'success').then(result => {
        window.location.href = '/bodypart';
      });
    } catch (error) {
      console.error("Error adding BodyPart:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="habit-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL+imagePath} style={{height: "200px"}} alt="Uploaded" />}
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}
