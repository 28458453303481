import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import getCookie from "./getCookie";
import "./Style.css";
import { serverURL } from "../serverURL";

export default function EditMeal() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState("free");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [meal, setMeal] = useState({
    title: "",
    imageUrl: "",
    type: [],
    proteinContent: 0,
    fatContent: 0,
    carbs: 0,
    calories: 0,
    servings: 0,
    price: "",
    description: "",
  });

  const handlePriceChange = (event) => {
    setSelectedPrice(event.target.value);
    setMeal({ ...meal, price: event.target.value });
  };

  useEffect(() => {
    async function fetchMeal() {
      try {
        const response = await axios.get(`${serverURL}meal/${id}`);
        setMeal({...response.data, type: response.data.type.map((part) => part._id)});
        setImagePath(response.data.imageUrl);
        setSelectedPrice(response.data.price);
      } catch (error) {
        console.log(error);
      }
    }

    fetchMeal();
  }, [id]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setMeal({
          ...meal,
          imageUrl: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${serverURL}mealtypes`)
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      const selectedValues = [...meal.type];

      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setMeal((prevData) => ({
        ...prevData,
        type: selectedValues,
      }));
    } else {
    setMeal((prevMeal) => ({
      ...prevMeal,
      [name]: value,
    }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${serverURL}meal/${id}`, meal, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire("Congrats", "Meal updated successfully!", "success").then(
        (result) => {
          window.location.href = "/meal";
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="edit-bodypart-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={meal.title}
            readOnly
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={meal.imageUrl}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Meal Type</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {options.map((option) => (
                <tr key={option._id}>
                  <td>{option.type_title}</td>
                  <td>
                    <input
                      type="checkbox"
                      id={option._id}
                      name="type"
                      value={option._id}
                      checked={meal.type.includes(option._id)}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && (
            <img
              src={serverURL + imagePath}
              style={{ height: "200px" }}
              alt="Uploaded"
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="proteinContent">Protein content</label>
          <input
            type="text"
            id="proteinContent"
            name="proteinContent"
            placeholder="Only enter numbers"
            value={meal.proteinContent}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="fatContent">Fat content</label>
          <input
            type="text"
            id="fatContent"
            name="fatContent"
            placeholder="Only enter numbers"
            value={meal.fatContent}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="carbs">Carbohydrates Content</label>
          <input
            type="text"
            id="fatContent"
            name="carbs"
            placeholder="Only enter numbers"
            value={meal.carbs}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="calories">Calories</label>
          <input
            type="text"
            id="fatContent"
            name="calories"
            placeholder="Only enter numbers"
            value={meal.calories}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="servings">Servings</label>
          <input
            type="text"
            id="servings"
            name="servings"
            placeholder="Only enter numbers"
            value={meal.servings}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">price</label>
          <div>
            <label>
              <input
                type="radio"
                value="free"
                checked={selectedPrice === "free"}
                onChange={handlePriceChange}
              />
              Free
            </label>

            <label>
              <input
                type="radio"
                value="premium"
                checked={selectedPrice === "premium"}
                onChange={handlePriceChange}
              />
              Premium
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={meal.description}
            onChange={handleChange}
            className="form-group"
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}
