import React, { useEffect } from 'react';
import getCookie from './getCookie';
import axios from 'axios';
import { serverURL } from '../serverURL';

function Logout() {

    const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);


    function deleteCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    function logout() {
        deleteCookie('token');
        window.location.href = '/login';
    }

    return (
        <div>
            <p>Are you sure you want to logout?</p>
            <button onClick={logout}>Logout</button>
        </div>
    );
}

export default Logout;
