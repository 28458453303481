import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function ShowMealType() {
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const navigate = useNavigate();

  const [meals, setMeals] = useState([]);

  useEffect(() => {
    async function getResults() {
      try {
        const data = await (await axios.get(`${serverURL}mealtypes`)).data;
        setMeals(data);
      } catch (error) {}
    }
    getResults();
  }, []);

  return (
    <>
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered">
                    <thead className="bs thead-dark">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {meals &&
                        meals.map((meal, index) => {
                          const handleEdit = () => {
                            navigate(`/mealtypes/edit/id=${meal._id}`);
                          };

                          const handleDelete = () => {
                            navigate(`/mealtypes/delete/id=${meal._id}`);
                          };

                          return (
                            <tr key={meal._id}>
                              <td>{index + 1}</td>
                              <td>{meal.type_title}</td>
                              <td>
                                {meal.product_image && (
                                  <img
                                    src={`${serverURL}` + meal.product_image}
                                    alt={meal.type_title}
                                    style={{ width: "100px" }}
                                  />
                                )}
                              </td>

                              <td>
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={handleEdit}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={handleDelete}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
