import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import './Style.css'
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddMealType() {
  
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState('');
  
  const [formData, setFormData] = useState({
    type_title: "",
    product_image: ""
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = () => {
    const formD = new FormData();
    formD.append('image', selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
      
        setFormData({
          ...formData,
          product_image: response.data.imagePath,
        });
        console.log('Image uploaded:', response.data.imagePath);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const selectedValues = [...formData.type_title];
       
      if (checked) {
        selectedValues.push(value);
      } else {
        const index = selectedValues.indexOf(value);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {  
      await axios.post(`${serverURL}mealtypes`, formData, {
        headers: {
            authorization: token,
        },
    });;
      
      Swal.fire('Congrats', 'Exercise added successfully!', 'success').then(result => {
        window.location.href = '/exercise'
      });
    } catch (error) {
      console.error("Error adding exercise:", error);
    }
  };

  return (
    <div>
      <br />
      <form onSubmit={handleSubmit} className="exercise-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="type_title"
            value={formData.type_title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
            type="file"
            id="image"
            name="product_image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && <img src={serverURL+imagePath} style={{height: "200px"}} alt="Uploaded" />}
        </div>
        
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}