import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import getCookie from "./getCookie";
import "./Style.css";
import { serverURL } from "../serverURL";

export default function EditDo() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [dos, setDos] = useState({
    title: "",
    type: "",
    imageUrl: "",
    description: "",
  });

  useEffect(() => {
    async function fetchDo() {
      try {
        const response = await axios.get(`${serverURL}do/${id}`);
        setDos(response.data);
        setImagePath(response.data.imageUrl);
      } catch (error) {
        console.log(error);
      }
    }

    fetchDo();
  }, [id]);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setDos({
          ...dos,
          imageUrl: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDos((prevDo) => ({
      ...prevDo,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (event) => {
    setDos((prevData) => ({
      ...prevData,
      type: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${serverURL}do/${id}`, dos, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire(
        "Congrats",
        "Do and donts updated successfully!",
        "success"
      ).then((result) => {
        window.location.href = "/do";
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="edit-bodypart-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={dos.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
          {imagePath && (
            <img
              src={serverURL + imagePath}
              style={{ height: "200px" }}
              alt="Uploaded"
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <table className="exercise-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="do">Do</label>
                </td>
                <td>
                  <input
                    type="radio"
                    id="do"
                    name="type"
                    value="do"
                    checked={dos.type === "do"}
                    onChange={handleCheckboxChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="dont">Don't</label>
                </td>
                <td>
                  <input
                    type="radio"
                    id="dont"
                    name="type"
                    value="dont"
                    checked={dos.type === "dont"}
                    onChange={handleCheckboxChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={dos.imageUrl}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <div>
            <textarea
              type="text"
              id="description"
              name="description"
              value={dos.description}
              onChange={handleChange}
              className="form-group"
            />
          </div>
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}
