import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";
import VideoPlayer from "./VideoPlayer";
import { Button } from "react-bootstrap";
export default function ShowYoga() {
  const token = getCookie("token");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${serverURL}getuser`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response.status !== 200) {
          window.location.href = "/login";
        }
      } catch (error) {
        window.location.href = "/login";
      }
    };
    getUser();
  }, [token]);

  const navigate = useNavigate();

  const [oneStretching, setOneStretching] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function getResults() {
      try {
        const data = await (await axios.get(`${serverURL}Yoga`)).data;
        console.log(data);
        setOneStretching(data);
      } catch (error) {}
    }
    getResults();
  }, []);

  const filteredOneStretchings = oneStretching.filter((oneStretching) =>
    oneStretching.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="Search by Title"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <table className="table table-bordered">
                    <thead className="bs thead-dark">
                      <tr>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Video</th>
                        <th>Operations</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oneStretching &&
                        filteredOneStretchings.map((oneStretching) => {
                          const handleEdit = () => {
                            navigate(
                              `/yoga/edit/id=${oneStretching._id}`
                            );
                          };

                          const handleDelete = () => {
                            navigate(
                              `/yoga/delete/id=${oneStretching._id}`
                            );
                          };

                          return (
                            <tr key={oneStretching._id}>
                              <td>{oneStretching.title}</td>
                              <td>
                                {oneStretching.image1 && (
                                  <img
                                    src={`${serverURL}` + oneStretching.image1}
                                    alt={oneStretching.title}
                                    style={{ width: "100px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {oneStretching.video && (
                                 <Button variant="primary" href={serverURL + oneStretching.video}>Play Video</Button>
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={handleEdit}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={handleDelete}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
