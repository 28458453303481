import React, { useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  // CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [exerciseClicked, setExerciseClicked] = useState(false);
  const [stretchingClicked, setStretchingClicked] = useState(false);
  const [meditationClicked, setMeditationClicked] = useState(false);
  const [habitClicked, setHabitClicked] = useState(false);
  const [habitTypeClicked, setHabitTypeClicked] = useState(false);
  const [doClicked, setDoClicked] = useState(false);
  const [doTypesClicked, setDoTypesClicked] = useState(false);
  const [bodypartClicked, setBodypartClicked] = useState(false);
  const [userClicked, setUserClicked] = useState(false);
  const [mealClicked, setMealClicked] = useState(false);
  const [logoutClicked, setLogoutClicked] = useState(false);
  const [mealtypeClicked, setmealtypeClicked] = useState(false);
  const [meditationMusicClicked, setmeditationMusicClicked] = useState(false);
  const [oneStretchingClicked, setOneStretchingClicked] = useState(false);
  const [productClicked, setProductClicked] = useState(false);
  const [productTypeClicked, setProductTypeClicked] = useState(false);
  const [aricle, setarticleClicked] = useState(false);
  const [testimonial, settestimonialClicked] = useState(false);
  const [yoga, setyogaClicked] = useState(false);
  const [bannerClicked, setBannerClicked] = useState(false);
  const [couponClicked, setCouponClicked] = useState(false);
  const handleToggle = (state, setState) => () => {
    setState(!state);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        float: "left",
        overflow: "scroll initial",
      }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            Admin
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/" activeclassname="activeClicked">
              <CDBSidebarMenuItem icon="home">Home</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/user"
              activeclassname="activeClicked"
              onClick={handleToggle(userClicked, setUserClicked)}
            >
              <CDBSidebarMenuItem icon="user">Users</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(exerciseClicked, setExerciseClicked)}
            >
              <CDBSidebarMenuItem icon="running">Exercise</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/referral"
              activeclassname="activeClicked"
              onClick={handleToggle(userClicked, setUserClicked)}
            >
              <CDBSidebarMenuItem icon="share">Referrals</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/reedem-requests"
              activeclassname="activeClicked"
              onClick={handleToggle(userClicked, setUserClicked)}
            >
              <CDBSidebarMenuItem icon="wallet">
                Reedem Requests
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/gd-coins"
              activeclassname="activeClicked"
              onClick={handleToggle(userClicked, setUserClicked)}
            >
              <CDBSidebarMenuItem icon="coins">GD Coins</CDBSidebarMenuItem>
            </NavLink>
            {exerciseClicked ? (
              <>
                <NavLink exact to="/exercise" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/exerciseform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(
                oneStretchingClicked,
                setOneStretchingClicked
              )}
            >
              <CDBSidebarMenuItem icon="male">
                One Stretching
              </CDBSidebarMenuItem>
            </NavLink>
            {oneStretchingClicked ? (
              <>
                <NavLink
                  exact
                  to="/onestretching"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/onestretchingform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(stretchingClicked, setStretchingClicked)}
            >
              <CDBSidebarMenuItem icon="dumbbell">
                Stretching
              </CDBSidebarMenuItem>
            </NavLink>
            {stretchingClicked ? (
              <>
                <NavLink exact to="/stretching" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/stretchingform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(meditationClicked, setMeditationClicked)}
            >
              <CDBSidebarMenuItem icon="om">Meditation</CDBSidebarMenuItem>
            </NavLink>
            {meditationClicked ? (
              <>
                <NavLink exact to="/meditation" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/meditationform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(testimonial, settestimonialClicked)}
            >
              <CDBSidebarMenuItem icon="om">Testimonial</CDBSidebarMenuItem>
            </NavLink>
            {testimonial ? (
              <>
                <NavLink
                  exact
                  to="/testimonial"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/addtestimonial"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(yoga, setyogaClicked)}
            >
              <CDBSidebarMenuItem icon="om">Yoga</CDBSidebarMenuItem>
            </NavLink>
            {yoga ? (
              <>
                <NavLink exact to="/yoga" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/addyoga" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(aricle, setarticleClicked)}
            >
              <CDBSidebarMenuItem icon="om">Article</CDBSidebarMenuItem>
            </NavLink>
            {aricle ? (
              <>
                <NavLink exact to="/articles" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/addarticle" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(habitClicked, setHabitClicked)}
            >
              <CDBSidebarMenuItem icon="fas fa-calendar-check">
                Habits
              </CDBSidebarMenuItem>
            </NavLink>
            {habitClicked ? (
              <>
                <NavLink exact to="/habit" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/habitform" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(doClicked, setDoClicked)}
            >
              <CDBSidebarMenuItem icon="fas fa-exclamation-circle">
                Do's and Dont's
              </CDBSidebarMenuItem>
            </NavLink>
            {doClicked ? (
              <>
                <NavLink exact to="/do" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/doform" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(mealClicked, setMealClicked)}
            >
              <CDBSidebarMenuItem icon="utensils">Meals</CDBSidebarMenuItem>
            </NavLink>
            {mealClicked ? (
              <>
                <NavLink exact to="/meal" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/mealform" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(bodypartClicked, setBodypartClicked)}
            >
              <CDBSidebarMenuItem icon="male">Body parts</CDBSidebarMenuItem>
            </NavLink>
            {bodypartClicked ? (
              <>
                <NavLink exact to="/bodypart" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/bodypartform"
                  activeclassname="activeClicked"
                  onClick={handleToggle(mealtypeClicked, setmealtypeClicked)}
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(
                meditationMusicClicked,
                setmeditationMusicClicked
              )}
            >
              <CDBSidebarMenuItem icon="columns">
                Meditation Music
              </CDBSidebarMenuItem>
            </NavLink>
            {meditationMusicClicked ? (
              <>
                <NavLink
                  exact
                  to="/meditationmusic"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/meditationmusicform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(productClicked, setProductClicked)}
            >
              <CDBSidebarMenuItem icon="utensils">Products</CDBSidebarMenuItem>
            </NavLink>
            {productClicked ? (
              <>
                <NavLink exact to="/product" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/productform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(habitTypeClicked, setHabitTypeClicked)}
            >
              <CDBSidebarMenuItem icon="columns">
                Habit Types
              </CDBSidebarMenuItem>
            </NavLink>
            {habitTypeClicked ? (
              <>
                <NavLink exact to="/habittype" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/habittypeform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(doTypesClicked, setDoTypesClicked)}
            >
              <CDBSidebarMenuItem icon="columns">
                Do and Don't Types
              </CDBSidebarMenuItem>
            </NavLink>
            {doTypesClicked ? (
              <>
                <NavLink exact to="/dotype" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/dotypeform" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(mealtypeClicked, setmealtypeClicked)}
            >
              <CDBSidebarMenuItem icon="columns">Meal Types</CDBSidebarMenuItem>
            </NavLink>
            {mealtypeClicked ? (
              <>
                <NavLink exact to="/mealtypes" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/mealtypesform"
                  activeclassname="activeClicked"
                  onClick={handleToggle(mealtypeClicked, setmealtypeClicked)}
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(productTypeClicked, setProductTypeClicked)}
            >
              <CDBSidebarMenuItem icon="utensils">
                Products Type
              </CDBSidebarMenuItem>
            </NavLink>
            {productTypeClicked ? (
              <>
                <NavLink
                  exact
                  to="/producttype"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/producttypeform"
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(bannerClicked, setBannerClicked)}
            >
              <CDBSidebarMenuItem icon="utensils">Banner</CDBSidebarMenuItem>
            </NavLink>
            {bannerClicked ? (
              <>
                <NavLink exact to="/banner" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/addBanner" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}
            <NavLink
              exact
              to=""
              activeclassname="activeClicked"
              onClick={handleToggle(couponClicked, setCouponClicked)}
            >
              <CDBSidebarMenuItem icon="utensils">Coupon</CDBSidebarMenuItem>
            </NavLink>
            {couponClicked ? (
              <>
                <NavLink exact to="/coupon" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Show all
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/coupon/add" activeclassname="activeClicked">
                  <CDBSidebarMenuItem icon="" style={{ textAlign: "center" }}>
                    Add new
                  </CDBSidebarMenuItem>
                </NavLink>{" "}
              </>
            ) : (
              <hr />
            )}

            <NavLink
              exact
              to="/logout"
              activeclassname="activeClicked"
              onClick={handleToggle(logoutClicked, setLogoutClicked)}
            >
              <CDBSidebarMenuItem icon="columns">Logout</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/hero404"
              target="_blank"
              activeclassname="activeClicked"
            ></NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
