import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../App.css";
import getCookie from "./getCookie";
import { serverURL } from "../serverURL";

export default function EditYoga() {
  const token = getCookie("token");

  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const response = await axios.get(`${serverURL}getuser`, {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       });
  //       if (response.status !== 200) {
  //         window.location.href = "/login";
  //       }
  //     } catch (error) {
  //       window.location.href = "/login";
  //     }
  //   };
  //   getUser();
  // }, [token]);

  const { id } = useParams();

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [videoPath, setVideoPath] = useState("");
  const [data, setdata] = useState({
    title: "",
    image: "",
    video: "",
    content: "",
    reps: "",
    sets: "",
    rest: "",
    level: "",
    price: ""
  });

  useEffect(() => {
    async function fetchOneStretching() {
      try {
        const response = await axios.get(`${serverURL}yoga/${id}`);
        setImagePath(response.data.image1);
        setVideoPath(response.data.video);
        setdata(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchOneStretching();
  }, [id]);



  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("image", selectedFile);

    axios
      .post(`${serverURL}upload`, formD)
      .then((response) => {
        setImagePath(response.data.imagePath);
        setdata({
          ...data,
          image: response.data.imagePath,
        });
        console.log("Image uploaded:", response.data.imagePath);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleVideoUpload = (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append("video", selectedFile);

    axios
      .post(`${serverURL}uploadvideo`, formD)
      .then((response) => {
        setVideoPath(response.data.videoPath);
        setdata({
          ...data,
          video: response.data.videoPath,
        });
        console.log("Video uploaded:", response.data.videoPath);
      })
      .catch((error) => {
        console.error("Error uploading video:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
      setdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${serverURL}yoga/${id}`, data, {
        // headers: {
        //   authorization: token,
        // },
      });

      Swal.fire(
        "Congrats",
        "Yoga updated successfully!",
        "success"
      ).then((result) => {
        window.location.href = "/yoga";
      });
    } catch (error) {
      console.log(error);
    }
  };

  const isSelected = (array, id) => array.includes(id.toString());

  return (
    <div>
      <form onSubmit={handleSubmit} className="exercise-form">
        <br />
        <div>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={data.title}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="imageUrl">Image:</label>
          <div>
            {imagePath && (
              <img
                src={serverURL + imagePath}
                style={{ height: "200px" }}
                alt="Uploaded"
              />
            )}
          </div>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Image</button>
        </div>
        <div className="form-group">
          <label htmlFor="videoUrl">Video:</label>
          <div>
            {videoPath && (
              <video
                src={serverURL + videoPath}
                style={{ height: "200px" }}
                controls
                alt="Uploaded"
              />
            )}
          </div>
          <input
            type="file"
            id="video"
            name="video"
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleVideoUpload}>Upload Video</button>
        </div>
        <div>
          <div>
            <label>Description:</label>
          </div>
          <textarea
            type="text"
            name="content"
            className="form-group"
            value={data.content}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <div>
            <label>Price:</label>
          </div>
          <textarea
            type="text"
            name="price"
            className="form-group"
            value={data.price}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Reps:</label>
          <input
            type="text"
            name="reps"
            placeholder="Only enter numbers"
            value={data.reps}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Sets:</label>
          <input
            type="text"
            name="sets"
            placeholder="Only enter numbers"
            value={data.sets}
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <label>Rest:</label>
          <input
            type="text"
            name="rest"
            placeholder="Only enter numbers"
            value={data.rest}
            onChange={handleChange}
          />
        </div>
        <br />
        
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
