import React, { useState } from "react";
import axios from "axios";
import { serverURL } from "../serverURL";
function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }

  const login = async (e) => {
    e.preventDefault();

    // window.location.href = "/reedem-requests";
    // return;
    try {
      const response = await axios.post(`${serverURL}login`, {
        Email: username,
        Password: password,
      });

      if (response.status === 200) {
        const { user } = response.data;
        if (username === "Greendumbells") {
          setCookie("token", user, 3);
          window.location.href = "/";
        }
      } else {
        window.alert("There is error in Login");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const google = () => {
  //   window.open(`${serverURL}auth/google`, "_self");
  // };

  // const facebook = () => {
  //   window.open(`${serverURL}auth/facebook`, "_self");
  // };

  return (
    <div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-5">
          <div className="bs">
            <h1>Greendumbells</h1>
            <h5>Login</h5>
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button className="btn btn-primary mt-3" onClick={login}>
              Login
            </button>
            <br />
            {/* <button className="btn btn-primary mt-3" onClick={google}>
             Google Login
            </button>
            <button className="btn btn-primary mt-3" onClick={facebook}>
             Facebook Login
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

// import { useState } from "react";
// import { LoginSocialFacebook } from "reactjs-social-login";
// import { FacebookLoginButton } from "react-social-login-buttons";

// function App() {
//   const [profile, setProfile] = useState(null);

//   return (
//     <div>
//       {!profile ? (
//         <LoginSocialFacebook
//           appId='824119999138525'
//           onResolve={(response) => {
//             console.log(response);
//             setProfile(response.data);
//           }}
//           onReject={(error) => {
//             console.log(error);
//           }}
//         >
//           <FacebookLoginButton />
//         </LoginSocialFacebook>
//       ) : (
//         ""
//       )}

//       {profile ? (
//         <div>
//           <h1>{profile.name}</h1>
//           <img src={profile.picture.data.url} />
//         </div>
//       ) : (
//         ""
//       )}
//     </div>
//   );
// }

// export default App;
