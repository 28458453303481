import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverURL";

const EditBanner = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [formData, setFormData] = useState({
        image: "",
        video: "",
    });

    useEffect(() => {
        const fetchBanner = async () => {
            try {
                const response = await fetch(`${serverURL}banner/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setFormData(data);
                } else {
                    console.error("Error:", response.status);
                }
            } catch (err) {
                console.error("Error:", err);
            }
        };

        fetchBanner();
    }, [id]);

    const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    const handleVideoChange = (event) => {
        setSelectedVideo(event.target.files[0]);
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        
        try {
            const formDataImage = new FormData();
            formDataImage.append("image", selectedImage);
            const response = await fetch(`${serverURL}upload`, {
                method: "POST",
                body: formDataImage,
            });

            if (response.ok) {
                const data = await response.json();
                setFormData({
                    ...formData,
                    image: data.imagePath,
                });
                console.log("Image uploaded:", data.imagePath);
            } else {
                throw new Error("Error uploading image");
            }
        } catch (error) {
            console.error("Error uploading media:", error);
        }
    };
    const handleUploadVideo = async (e) => {
        e.preventDefault();
        try {
            const formDataVideo = new FormData();
            formDataVideo.append("video", selectedVideo);
            const response = await fetch(`${serverURL}uploadvideo`, {
                method: "POST",
                body: formDataVideo,
            });

            if (response.ok) {
                const data = await response.json();
                setFormData({
                    ...formData,
                    video: data.videoPath,
                });
                console.log("Video uploaded:", data.videoPath);
            } else {
                throw new Error("Error uploading video");
            }
        } catch (error) {
            console.error(error)
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.image && formData.video) {
            Swal.fire("Warning", "You can only add either a banner image or a banner video, not both", "warning");
            return;
        }
        if (!formData.image && !formData.video) {
            Swal.fire("Warning", "Please select either a banner image or a banner video", "warning");
            return;
        }
        try {
            const response = await fetch(`${serverURL}banner/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                Swal.fire("Congrats", "Banner updated successfully!", "success").then((result) => {
                    if (result.isConfirmed) {
                        navigate("/showbanners");
                    }
                });
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Edit Banner</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label htmlFor="image" className="block mb-2">
                            Image:
                        </label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={handleImageChange}
                            className="border rounded p-2 mb-2"
                        />
                        <button
                            onClick={handleUploadImage}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Upload Image
                        </button>
                        {formData.image && (
                            <img
                                src={`${serverURL}` + formData.image}
                                alt="Uploaded Image"
                                style={{ height: "200px" }}
                                className="mt-2"
                            />
                        )}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="video" className="block mb-2">
                            Video:
                        </label>
                        <input
                            type="file"
                            id="video"
                            name="video"
                            onChange={handleVideoChange}
                            className="border rounded p-2 mb-2"
                        />
                        <button
                            onClick={handleUploadVideo}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Upload Video
                        </button>
                        {formData.video && (
                            <video
                                src={`${serverURL}` + formData.video}
                                alt="Uploaded Video"
                                style={{ height: "200px" }}
                                controls
                                className="mt-2"
                            />
                        )}
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditBanner;
