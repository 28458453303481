import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import Sidebar from "./Sidebar";
import "../App.css";
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function ShowMeal() {

	const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);


	const navigate = useNavigate();

	const [meals, setMeals] = useState([])
	useEffect(() => {
		async function getResults() {
			try {
				const data = await (await axios.get(`${serverURL}meal`)).data
				setMeals(data)
			} catch (error) {
			}
		}
		getResults()
	}, []);

	return (
		<>
			<div className="dashboard d-flex">
				<div>
					<Sidebar />
				</div>
				<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
					<div style={{ height: "100%" }}>
						<div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>

							<div className='row'>
								<div className="col-md-12">
									<table className='table table-bordered'>
										<thead className='bs thead-dark'>
											<tr>
												<th>#</th>
												<th>Title</th>
												<th>Image</th>
												<th>Category</th>
                                                <th>Protein content</th>
                                                <th>Carbs</th>
                                                <th>Calories</th>
												<th>Fat content</th>
												<th>Servings</th>
                                                <th>Operations</th>
											</tr>
										</thead>
										<tbody>
											{meals && (meals.map((meal,index) => {

                                                const handleEdit = () => {
													navigate(`/meal/edit/id=${meal._id}`);
												  };
									  
												  const handleDelete = () => {
													navigate(`/meal/delete/id=${meal._id}`);
												  };
									  
												return <tr key={meal._id}>
													<td>{index+1}</td>
													<td>{meal.title}</td>
													<td>{meal.imageUrl && (<img src={`${serverURL}` + meal.imageUrl} alt={meal.title} style={{ width: "100px" }} />)}</td>
                                                    <td>{meal.type.map((type) => type.type_title + ", ")}</td>
                                                    <td>{meal.proteinContent}</td>
                                                    <td>{meal.carbs}</td>
                                                    <td>{meal.calories}</td>
                                                    <td>{meal.fatContent}</td>
                                                    <td>{meal.servings}</td>
													<td><button className="btn btn-sm btn-primary" onClick={handleEdit}>Edit</button>
														<button className="btn btn-sm btn-danger" onClick={handleDelete}>Delete</button></td>
												</tr>
											}))}
										</tbody>
									</table>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}
