import React, { useRef } from "react";

const VideoPlayer = (props) => {
  const videoRef = useRef(null);

  return (
    <video
      controls
      ref={videoRef}
      src={props.src}
      type="video/mp4"
      style={{ height: "200px" }}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
