import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faRunning, faSignOut, faCalendarCheck, faOm, faMale, faExclamationCircle, faUtensils } from '@fortawesome/free-solid-svg-icons';
import './Style.css';
import { serverURL } from '../serverURL';
import getCookie from './getCookie';

export default function Home() {
//     const token= getCookie('token')
//   useEffect(() => {
//     const getUser=async()=>{
//       const response = await axios.get(`${serverURL}getuser`, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       })
//       if(response.status!==200){
//         window.location.href = '/login';
//       }
//     }
//     getUser()
//   }, []);
const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

    const urls = [
        `${serverURL}exercise`,
        `${serverURL}stretching`,
        `${serverURL}bodypart`,
        `${serverURL}habit`,
        `${serverURL}do`,
        `${serverURL}meditation`,
        `${serverURL}articles`,
        `${serverURL}testimonial`,
        `${serverURL}yoga`,
        `${serverURL}meal`
    ];
    const [lengthArray, setLengthArray] = useState([]);

    async function fetchData() {
        try {
            const tempLengthArray = [];

            for (const url of urls) {
                const response = await axios.get(url);
                tempLengthArray.push(response.data.length);
            }
            setLengthArray(tempLengthArray);
        } catch (error) {
            console.error(error);

        }
    }
    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div>
            <div className="dashboard d-flex">
                <div>
                    <Sidebar />
                </div>
                <div className="container-fluid bg-light">
                    <div className="row simple-box">
                        <div className="col d-flex gap-10 align-items-center">
                            <h2 className='mx-2'>Welcome, <strong style={{ textDecoration: "Bold" }}>Admin</strong></h2>
                            <FontAwesomeIcon icon={faSignOut} className="icon" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <h3>Sections</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                    {{ lengthArray } !== 0 ? <p>{lengthArray[0]}</p> :null}
                                    <h4>Exercise</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faRunning} className="icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[1]}</p> :null}
                                    <h4>Stretching</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faDumbbell} className="icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[2]}</p> :null}
                                    <h4>BodyParts</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faMale} className="icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[3]}</p> :null}
                                    <h4>Habits</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faCalendarCheck} className="icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[4]}</p> :null}
                                    <h4>Do's/Dont's</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[5]}</p> :null}
                                    <h4>Meditation</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faOm} className="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[6]}</p> :null}
                                    <h4>Article</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faOm} className="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[7]}</p> :null}
                                    <h4>Testimonial</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faOm} className="icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[8]}</p> :null}
                                    <h4>Yoga</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faOm} className="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="box">
                                <div className="box-content">
                                {{ lengthArray } !== 0 ? <p>{lengthArray[9]}</p> :null}
                                    <h4>Meals</h4>
                                </div>
                                <div className="box-icon">
                                    <FontAwesomeIcon icon={faUtensils} className="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
