import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import './Style.css'
import getCookie from "./getCookie";
import {serverURL} from "../serverURL";

export default function AddMeditationMusic() {
  
  const token= getCookie('token')
  useEffect(() => {
      const getUser=async()=>{
            try{
            const response = await axios.get(`${serverURL}getuser`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if(response.status!==200){
                window.location.href = '/login';
            }
        }catch(error){
            window.location.href = '/login';
        }
        }
    getUser()
  }, [token]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [musicPath, setMusicPath] = useState('');

  const [formData, setFormData] = useState({
    title: "",
    music: "",
   
  });
//   console.log(formData);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = (e) => {
    e.preventDefault()
    const formD = new FormData();
    formD.append('music', selectedFile);
    // console.log(formD);
    axios
      .post(`${serverURL}uploadmusic`, formD)
      .then((response) => {
        // console.log(response.data)
        setMusicPath(response.data.musicPath);
       
        setFormData({
          ...formData,
          music: response.data.musicPath,
        });
        console.log('Music uploaded:', response.data.musicPath);
      })
      .catch((error) => {
        console.error('Error uploading music:', error);
      });
  };
  const handleChange = (e) => {
    const { name, value} = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${serverURL}meditationmusic`, formData, {
        headers: {
            authorization: token,
        },
    });
      Swal.fire('Congrats', 'Music added successfully!', 'success').then(result => {
        window.location.href = '/music'
      })
    } catch (error) {
      console.error("Error adding meditation:", error);
    }
  };

  return (
    <div className="habit-form">
      <br />
      <form onSubmit={handleSubmit} className="meditationMusic-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="music">Music:</label>
          <input
            type="file"
            id="music"
            name="music"
            
            onChange={handleFileChange}
            className="form-input"
          />
          <button onClick={handleUpload}>Upload Music</button>
          {musicPath && <audio src={musicPath} alt="Uploaded" />}
        </div>
       
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}
